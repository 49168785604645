import React, { useState } from 'react';
import Login from './components/login/Login';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { 
  BrowserRouter as Router,
  Routes,
  Route, 
  Navigate } from 'react-router-dom';
import PrivateRoute from './components/shared/PrivateRoute';
import Header from './components/Header';
import FormTemplate from './components/formTemplate/FormTemplate';
import UserManagement from './components/user-management/UserManagement';
import Forms from './components/form/Forms';
import './App.css'
import FormForAnonymous from './components/form/FormForAnonymous';

const App: React.FC = () => {
  const [authenticated, setAuthenticated] = useState(false);

  return (
    <Provider store={store}>
      <Router>
        {authenticated ? <Header></Header> : null}
        <Routes>
          <Route path="/login" element={<Login authenticated={authenticated} setAuthenticated={setAuthenticated}/>}></Route>
          {/* <Route 
            path="/dashboard"
            element={<PrivateRoute
                        path="/dashboard"
                        component={
                          <Dashboard/>
                        }
                      ></PrivateRoute>}>            
          </Route> */}
          <Route 
            path="/templates"
            element={<PrivateRoute
                        path="/templates"
                        component={
                          <FormTemplate/>
                        }
                      ></PrivateRoute>}>            
          </Route>
          <Route 
            path="/userManagement"
            element={<PrivateRoute
                        path="/userManagement"
                        component={
                          <UserManagement/>
                        }
                      ></PrivateRoute>}>            
          </Route>
          <Route 
            path="/forms"
            element={<PrivateRoute
                        path="/forms"
                        component={
                          <Forms/>
                        }
                      ></PrivateRoute>}>            
          </Route>
          <Route path="/fillForm/:id" element={<FormForAnonymous/>}/>
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
