import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import axios from "axios";
import FormTemplateGenerator from "../survey/FormTemplateGenerator";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { DataGrid, GridColDef, GridMenuIcon, GridRenderCellParams } from "@mui/x-data-grid";
import CloseIcon from '@mui/icons-material/Close';
import FormTemplateStatistics from "../survey/statistics/FormTemplateStatistics";
import { useTranslation } from "react-i18next";

export interface IFormTemplate {
  name: string;
  description: string;
  _id: string;
  created_at: Date;
  published: boolean;
  published_from: Date | null;
  published_to: Date | null;
  current_file_id: string;
}

const modalFormStyle = {
  bgcolor: 'background.paper',
};

const FormTemplateManagement: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const [templateGeneratorOpened, setTemplateGeneratorOpened] = useState(false);
  const [templateStatisticsOpened, setTemplateStatisticsOpened] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [formTemplates, setFormTemplates] = useState<IFormTemplate[]>([]);
  const [selectedFormTemplate, setSelectedFormTemplate] = useState<IFormTemplate | null>(null);

  const jwt = useAppSelector((state: any) => state.auth.jwt);
  const userId = useAppSelector((state: any) => state.auth.id);

  // Anchor for menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuFormTemplate, setMenuFormTemplate] = useState<null | IFormTemplate>(null);

  useEffect(() => {
    const getAllTemplates = async () => {
      const formTemplatesResponse = await axios.post(`/api/FormTemplates/GetFormTemplates`, {}, { headers: {
        'Authorization': 'Bearer ' + jwt
      }});

      let formTemplatesObject: IFormTemplate[] = [];
      
      if (formTemplatesResponse && formTemplatesResponse.data && formTemplatesResponse.data.form_templates) {
        formTemplatesObject = formTemplatesResponse.data.form_templates.map((formTemplate: IFormTemplate) => {
          if (formTemplate.published_to && formTemplate.published_from) {
            const currentTime = new Date();
            if (new Date(formTemplate.published_from) > new Date(formTemplate.published_to)) {
              formTemplate.published = true;
            } else {
              formTemplate.published = !(currentTime > new Date(formTemplate.published_to));
            }
          } else if (formTemplate.published_from) {
              formTemplate.published = true;
          } else {
            formTemplate.published = false; 
          }
          return formTemplate;
        });
      }

      const allFormTemplates: IFormTemplate[] = [...formTemplatesObject];
      setFormTemplates(allFormTemplates);
    };
    
    getAllTemplates();
  }, [jwt]);

  
  const columns: GridColDef[] = [
    { field: 'name', headerName: t('formTemplate.management.name'), flex: 3 },
    { field: '_id', headerName: t('formTemplate.management.ID'), flex: 2 },
    { field: 'created_at', headerName: t('formTemplate.management.created'), flex: 3 },
    { field: 'published', headerName: t('formTemplate.management.published'), flex: 2 },
    { field: 'actions', headerName: t('formTemplate.management.actions'), flex: 1, 
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams<any, Date>) => {
      return (<IconButton aria-label="menu" size="large" onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event, params.row)}>
        <GridMenuIcon />
      </IconButton>);
      }, }
  ];

  const publishFormTemplate = async () => {

    const formTemplate = menuFormTemplate;
    if (!formTemplate) return;

    // Map the template with a workflow
    // a9d199db14bf4c35a98706cc1211d15e / default workflow
    await axios.post(`/api/FormTemplates/SetFormTemplateWorkflow`, 
      {
        'form_template_id': formTemplate._id,
        'workflow_template_id': 'a9d199db14bf4c35a98706cc1211d15e'
      },
      { headers: {
        'Authorization': 'Bearer ' + jwt
      }}
    );

    const body = {
      'form_template_id': formTemplate._id,
      'roles_id': [
        userId
      ],
      'users_id': [
        userId,
        'cdd0ad37b4dd4cfc8fd96d3c2cee7a72' // Added one of the users hardcoded
      ],
      'available_to_anonymous': true,
      'date_from': null
    };

    // Publish
    await axios.post(`/api/FormTemplates/PublishFormTemplate`, body, { headers: {
      'Authorization': 'Bearer ' + jwt
    }});

    reloadData();
    setAnchorEl(null);
  }
  
  const unpublishFormTemplate = async () => {
    const formTemplate = menuFormTemplate;
    if (!formTemplate) return;

    const body = {
      'form_template_id': formTemplate._id
    };

    // Unpublish
    await axios.post(`/api/FormTemplates/UnpublishFormTemplate`, body, { headers: {
      'Authorization': 'Bearer ' + jwt
    }});

    setTimeout(() => {
      reloadData();
      setAnchorEl(null);
    }, 200);
  }
  
  const deleteFormTemplate = async () => {
    const formTemplate = menuFormTemplate;
    if (!formTemplate) return;

    const body = {
      'form_template_id': formTemplate._id,
      "with_forms": true // TODO Valid?
    };

    // Unpublish
    await axios.post(`/api/FormTemplates/DeleteFormTemplate`, body, { headers: {
      'Authorization': 'Bearer ' + jwt
    }});

    reloadData();
    setAnchorEl(null);
    setOpenDeleteDialog(false);
  }

  // Anchor actions
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, formTemplate: IFormTemplate) => {
    setAnchorEl(event.currentTarget);
    setMenuFormTemplate(formTemplate);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  
  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const openTemplateCreationModal = () => {
    setSelectedFormTemplate(null);
    setTemplateGeneratorOpened(true);
  }
  
  const closeTemplateGenerator = async () => {
    reloadData();
    setTemplateGeneratorOpened(false);
  };
  
  const closeStatistics = async () => {
    reloadData();
    setTemplateStatisticsOpened(false);
  };

  const reloadData = async () => {
    // reload data
    const formTemplatesResponse = await axios.post(`/api/FormTemplates/GetFormTemplates`, {}, { headers: {
      'Authorization': 'Bearer ' + jwt
    }});

    let formTemplatesObject: IFormTemplate[] = [];

    if (formTemplatesResponse && formTemplatesResponse.data && formTemplatesResponse.data.form_templates) {
      formTemplatesObject = formTemplatesResponse.data.form_templates.map((formTemplate: IFormTemplate) => {
        if (formTemplate.published_to && formTemplate.published_from) {
          const currentTime = new Date();
          if (new Date(formTemplate.published_from) > new Date(formTemplate.published_to)) {
            formTemplate.published = true;
          } else {
            formTemplate.published = !(currentTime > new Date(formTemplate.published_to));
          }
        } else if (formTemplate.published_from) {
            formTemplate.published = true;
        } else {
          formTemplate.published = false; 
        }
        return formTemplate;
      });
    }

    const allFormTemplates: IFormTemplate[] = [...formTemplatesObject];
    setFormTemplates(allFormTemplates);
  }

  const editFormTemplate = () => {
    const formTemplate = menuFormTemplate;
    if (!formTemplate) return;
    
    setSelectedFormTemplate(formTemplate);

    // Open modal with template generator
    setTemplateGeneratorOpened(true);
    setAnchorEl(null);
  }

  const openFormTemplateStatistics = () => {
    const formTemplate = menuFormTemplate;
    if (!formTemplate) return;
    
    setSelectedFormTemplate(formTemplate);

    // Open modal with statistics
    setTemplateStatisticsOpened(true);
    setAnchorEl(null);
  }

  const exportDataFromFormTemplate = async () => {
    const formTemplate = menuFormTemplate;
    if (!formTemplate) return;
    
    // Export given data
    const body = {
      'from_index': 0,
      'to_index': 0,
      'form_template_id': formTemplate._id,
      'file_name': formTemplate._id + ' export',
      'sheet_name': formTemplate.name
    }

    const formTemplatesResponse = await axios.post(`/api/Data/ExportDataFromForms`, body, { headers: {
      'Authorization': 'Bearer ' + jwt
    }});

    if (!formTemplatesResponse.data.file_id) {
      return;
    }
    
    const formFile = await axios.get(`/api/Files/Download`, { params: {
      file_id: formTemplatesResponse.data.file_id
    }, responseType: 'arraybuffer', headers: {
      'Authorization': 'Bearer ' + jwt,
      
    }});
    
    const blobData = new Blob([formFile.data], {
      type: 'application/vnd.ms-excel'
    });
    const csvURL = window.URL.createObjectURL(blobData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', formTemplate._id + ' export.xlsx');
    tempLink.click();
    tempLink.remove();
  }

  return (
    <>
      <div>
        <Button variant="contained" onClick={openTemplateCreationModal} size="medium" sx={{
                'float': 'right', 'margin': '8px',
              }}><AddIcon sx={{'marginRight': '8px'}}></AddIcon>{t('formTemplate.management.addTemplate')}</Button>
      </div>
    <br/>
    <DataGrid
            rows={formTemplates}
            columns={columns}
            // onRowClick={handleRowSelection}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            getRowId={(row)=>row._id}
            pageSizeOptions={[5, 20, 40]}
            sx={{width: '100%'}}
          />
      
      {menuFormTemplate?.published === true ? 
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={createForm}>Create Form</MenuItem> */}
          <MenuItem onClick={editFormTemplate}>{t('formTemplate.management.actionMenu.edit')}</MenuItem>
          <MenuItem onClick={unpublishFormTemplate}>{t('formTemplate.management.actionMenu.unpublish')}</MenuItem>
          <MenuItem onClick={openFormTemplateStatistics}>{t('formTemplate.management.actionMenu.viewStatistics')}</MenuItem>
          <MenuItem onClick={exportDataFromFormTemplate}>{t('formTemplate.management.actionMenu.exportReport')}</MenuItem>
        </Menu> :
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={editFormTemplate}>{t('formTemplate.management.actionMenu.edit')}</MenuItem>
          <MenuItem onClick={publishFormTemplate}>{t('formTemplate.management.actionMenu.publish')}</MenuItem>
          <MenuItem onClick={handleOpenDeleteDialog}>{t('formTemplate.management.actionMenu.delete')}</MenuItem>
        </Menu>
      }
      
      {/* Delete dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('formTemplate.management.deleteDialog.delete')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('formTemplate.management.deleteDialog.deleteMessage')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseDeleteDialog}>
            {t('formTemplate.management.deleteDialog.cancel')}
          </Button>
          <Button onClick={deleteFormTemplate} color='error'>{t('formTemplate.management.deleteDialog.delete')}</Button>
        </DialogActions>
      </Dialog>

      {/* Form Template Generator / Template detail for edit */}
      <Dialog
        fullScreen={true}
        open={templateGeneratorOpened}
        onClose={closeTemplateGenerator}
      >
        <DialogTitle>{selectedFormTemplate ? t('formTemplate.management.templateGenerator.edit') : t('formTemplate.management.templateGenerator.create')}{' ' + t('formTemplate.management.templateGenerator.formTemplate')}</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={closeTemplateGenerator}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
            <Box sx={modalFormStyle}>
              <FormTemplateGenerator formTemplate={selectedFormTemplate} onSubmit={closeTemplateGenerator}></FormTemplateGenerator>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeTemplateGenerator}>{t('formTemplate.management.templateGenerator.close')}</Button>
        </DialogActions>
      </Dialog>
      
      {/* Form Template Statistics */}
      <Dialog
        fullScreen={true}
        open={templateStatisticsOpened}
        onClose={closeStatistics}
      >
        <DialogTitle>{t('formTemplate.management.templateStatistics.title')}</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={closeStatistics}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        <DialogContent>
          <DialogContentText>
          </DialogContentText>
            <Box sx={modalFormStyle}>
              <FormTemplateStatistics formTemplate={selectedFormTemplate} onClose={closeStatistics}></FormTemplateStatistics>
            </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeStatistics}>{t('formTemplate.management.templateStatistics.close')}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
};

export default FormTemplateManagement;
