import * as React from 'react';
import { useTranslation } from 'react-i18next';

const FormTemplateWorkFlow: React.FC = () => {
  const { t } = useTranslation(['translation']);
  return (
    <div>
        {t('templateWorkflow.tbd')}
    </div>
  );
}

export default FormTemplateWorkFlow;
