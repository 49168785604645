import React, { useState } from "react";
import { DataGrid, GridColDef, GridMenuIcon, GridRenderCellParams } from '@mui/x-data-grid';
import UserDetail from "./UserDetail";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import UserStatistics from "../survey/statistics/UserStatistics";
import { useTranslation } from "react-i18next";

interface UserListProps {
  users: any[],
  roles: any[],
  reload: () => void
}

const UserList: React.FC<UserListProps> = props => {
  const { t } = useTranslation(['translation']);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDetailOpened, setUserDetailOpened] = useState(false);
  const [userStatisticsOpened, setUserStatisticsOpened] = useState(false);
  const [createUserOpened, setCreateUserOpened] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuUser, setMenuUser] = useState<any>(null);
  
  const menuOpen = Boolean(anchorEl);

  // const handleRowSelection = (params: GridRowParams) => {
  //   setSelectedUser(params.row);

  //   // Open modal with user details
  //   setUserDetailOpened(true);
  // }

  const openCreateNewUser = () => {
    setCreateUserOpened(true);
  }
  
  const closeStatistics = async () => {
    props.reload();
    setUserStatisticsOpened(false);
  }

  // const closeDetail = async () => {
  //   props.reload();
  //   setUserDetailOpened(false);
  // }
  
  const openUserDetail = () => {
    const user = menuUser;
    if (!user) return;
    
    setSelectedUser(user);

    // Open modal with statistics
    setUserDetailOpened(true);
    setAnchorEl(null);
  }
  
  const openUserStatistics = () => {
    const user = menuUser;
    if (!user) return;
    
    setSelectedUser(user);

    // Open modal with statistics
    setUserStatisticsOpened(true);
    setAnchorEl(null);
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, user: any) => {
    setAnchorEl(event.currentTarget);
    setMenuUser(user);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns: GridColDef[] = [
    { field: 'display_name', headerName: t('userManagement.list.name'), flex: 3 },
    { field: 'email', headerName: t('userManagement.list.email'), flex: 3 },
    { field: 'login', headerName: t('userManagement.list.loginName'), flex: 2 },
    { field: 'registration_date', headerName: t('userManagement.list.created'), flex: 2 },
    { field: 'actions', headerName: t('userManagement.list.actions'), flex: 1, 
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<any, Date>) => {
        return (<IconButton aria-label="menu" size="large" onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event, params.row)}>
          <GridMenuIcon />
        </IconButton>);
        }, }
  ];

  return (
    <>
      <div>
        <Button variant="contained" 
                onClick={openCreateNewUser}
                size="small"
                sx={{
                  'float': 'right', 'margin': '8px',
                }}>
          <AddIcon sx={{'marginRight': '8px'}}></AddIcon>{t('userManagement.list.createUser')}
        </Button>
      </div>

      <DataGrid
            rows={props.users}
            columns={columns}
            // onRowClick={handleRowSelection}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            pageSizeOptions={[5, 20, 40]}
            sx={{width: '100%'}}
          />

        <Menu
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
        >
          <MenuItem onClick={openUserDetail}>{t('userManagement.list.menu.showDetail')}</MenuItem>
          <MenuItem onClick={openUserStatistics}>{t('userManagement.list.menu.openStatistics')}</MenuItem>
        </Menu>
          
        {/* Modal window for user creation*/}
        {createUserOpened ? <UserDetail open={createUserOpened} create={true} roles={props.roles} onClose={() => {setCreateUserOpened(false)}} reload={props.reload}></UserDetail> : null}
        
        {/* Modal window for user detail*/}
        {userDetailOpened ? <UserDetail open={userDetailOpened} create={false} roles={props.roles} user={selectedUser} onClose={() => {setUserDetailOpened(false)}} reload={props.reload}></UserDetail> : null}
        
        {/* Form Template Statistics */}
        <Dialog
          fullScreen={true}
          open={userStatisticsOpened}
          onClose={closeStatistics}
        >
          <DialogTitle>{t('userManagement.list.statistics.userStatistics')}</DialogTitle>
          <IconButton
              aria-label="close"
              onClick={closeStatistics}
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          <DialogContent>
            <DialogContentText>
            </DialogContentText>
              <Box sx={{bgcolor: 'background.paper'}}>
                <UserStatistics user={selectedUser} onClose={closeStatistics}></UserStatistics>
              </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeStatistics}>{t('userManagement.list.statistics.close')}</Button>
          </DialogActions>
        </Dialog>
    </>
  )
};

export default UserList;
