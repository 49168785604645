import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { UserRole } from '../constants/UserRole'

// Define a type for the slice state
export interface AuthState {
    isAuthenticated: boolean,
    jwt: string,
    id: string,
    userRole: UserRole
  }
  
// Define the initial state using that type
const initialState: AuthState = {
  isAuthenticated: false,
  jwt: '',
  id: '',
  userRole: UserRole.None
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload
    },
    setJWT: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload
    },
    setID: (state, action: PayloadAction<string>) => {
      state.id = action.payload
    },
    setUserRole: (state, action: PayloadAction<string>) => {
      switch (action.payload) {
        case 'admin':
          state.userRole = UserRole.Admin
          break;
        case 'user':
          state.userRole = UserRole.User
          break;
        case 'none':
          state.userRole = UserRole.None
          break;
        default:
          state.userRole = UserRole.None
          break;
      }
    },
  }
})

// Action creators are generated for each case reducer function
export const { setAuthenticated, setJWT, setID, setUserRole } = authSlice.actions

export default authSlice.reducer


