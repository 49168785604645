import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import axios from "axios";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { IFormItem } from "../survey/FormTemplateGenerator";
import FormItem from "../survey/questions/FormItem";
import { DataGrid, GridColDef, GridMenuIcon, GridRenderCellParams } from "@mui/x-data-grid";
import CloseIcon from '@mui/icons-material/Close';
import { FormItemType } from "../survey/questions/IFormItem";
import { useTranslation } from "react-i18next";

interface FormTemplate {
  name: string;
  description: string;
  id: string;
}

const FormsToCreate: React.FC = () => {
  const { t, i18n } = useTranslation(['translation']);
  const [formLanguage, setFormLanguage] = useState(i18n.language);

  // Form filling
  const [openForm, setOpenForm] = useState(false);

  // Dialogs
  const [, setFormMandatoryError] = useState(false);
  const [warningDialogOpened, setWarningDialogOpened] = useState(false);
  const [submitConfirmDialogOpened, setSubmitConfirmDialogOpened] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  
  const [filledFormFileId, setFilledFormFileId] = useState('');
  const [filledFormId, setFilledFormId] = useState('');
  const [formItems, setFormItems] = useState<IFormItem[]>([]);
  const [formTemplates, setFormTemplates] = useState<FormTemplate[]>([]);
  
  const [formRecordedValues, setFormRecordedValues] = useState<any>([]);
  const jwt = useAppSelector((state: any) => state.auth.jwt);

  // Anchor for menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuFormTemplate, setMenuFormTemplate] = useState<null | FormTemplate>(null);

  useEffect(() => {
    const getAllTemplates = async () => {
      // published only
      const publishedFormTemplatesResponse = await axios.post(`/api/Forms/GetPublishedFormTemplates`, {}, { headers: {
        'Authorization': 'Bearer ' + jwt
      }});
      
      let publishedFormTemplatesObject: FormTemplate[] = [];
      
      if (publishedFormTemplatesResponse && publishedFormTemplatesResponse.data && publishedFormTemplatesResponse.data.form_templates) {
        publishedFormTemplatesObject = publishedFormTemplatesResponse.data.form_templates;
      }

      setFormTemplates([...publishedFormTemplatesObject]);
    };
    
    getAllTemplates();
  }, [jwt]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('forms.formsToCreate.name'), flex: 3 },
    { field: 'description', headerName: t('forms.formsToCreate.description'), flex: 5 },
    { field: 'actions', headerName: t('forms.formsToCreate.actions'), flex: 0.5, 
    sortable: false,
    filterable: false,
    renderCell: (params: GridRenderCellParams<any, Date>) => (
      <IconButton aria-label="menu" size="large" onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleClick(event, params.row)}>
        <GridMenuIcon />
      </IconButton>
    ), }
  ];

  const createForm = async () => {
    // do nothing yet
    
    const formTemplate = menuFormTemplate;
    if (!formTemplate) return;

    // Map the template with a workflow
    // a9d199db14bf4c35a98706cc1211d15e / default workflow
    const body = {
      'form_template_id': formTemplate.id,
      'form_name': formTemplate.name,
      'form_description': formTemplate.description
    };

    const createForm = await axios.post(`/api/Forms/CreateForm`, body, { headers: {
      'Authorization': 'Bearer ' + jwt
    }});

    setFilledFormFileId(createForm.data.form_file_id);
    setFilledFormId(createForm.data.form_id);
    // Download form file
    const formFile = await axios.get(`/api/Files/Download`, { params: {
      file_id: createForm.data.form_file_id
    }, headers: {
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'multipart/form-data'
    }});

    const downloadedTemplate = JSON.stringify(formFile.data);
    let downloadedForm;
    if (JSON.parse(downloadedTemplate).language && JSON.parse(downloadedTemplate).form) {
      downloadedForm = JSON.parse(downloadedTemplate).form;
      const language = JSON.parse(downloadedTemplate).language;
      setFormLanguage(language);
    } else {
      downloadedForm = downloadedTemplate;
    }

    let parsedFormFile: IFormItem[] = JSON.parse(downloadedForm);
    setFormItems([...parsedFormFile]);
    setOpenForm(true);
  }
  
  const handleCloseFormModal = () => {
    setAnchorEl(null);
    setOpenForm(false);
  };

  const markChildFormItem = (formItem: IFormItem, id: string) => {
    if (!formItem.children || formItem.children.length === 0) return;
    formItem.children = formItem.children.map(childFormItem => {
      if (childFormItem.id === id) {
        childFormItem.shouldDisplaySubmitError = true;
      } else if (childFormItem.children) {
        markChildFormItem(childFormItem, id);
      }
      return childFormItem;
    });
  }

  const prepareChildrenForSubmit = (children: IFormItem[], valid: boolean, empty: boolean): [any[], boolean, boolean] => {
    const submitObjects = [];
    for (const item of children) {
      if (item.additionalParams && item.additionalParams.mandatory && item.type !== FormItemType.questionContainer) {
        /** We need to check whether selected value is empty, however there is possibility for single choice,
         * which can be already defined as empty string, thus we need to ignore it.
        */
        if (((item.selectedValue?.length === 0 && item.selectedValue?.toString() === '' && item.type !== FormItemType.questionSingleChoice) || (item.selectedValue === null || item.selectedValue === undefined)) && (item.additionalParams.mandatory === true)) {
          valid = false;
          setFormItems(previousFormItems => {
            return [...previousFormItems].map((formItem) => {
              if (item.id === formItem.id) {
                formItem.shouldDisplaySubmitError = true;
              }
              if (formItem.children) {
                markChildFormItem(formItem, item.id);
              }
              return formItem;
            });
          });
        }
      } else if (((item.selectedValue?.toString() === '' && item.type !== FormItemType.questionSingleChoice) || (item.selectedValue === null || item.selectedValue === undefined)) && item.type !== FormItemType.customText) {
        empty = true;
      }
      if (item.type === FormItemType.questionContainer && item.children && item.children.length > 0) {
        const submitChildren = prepareChildrenForSubmit(item.children, valid, empty);
        if (submitChildren[0] && submitChildren[0].length > 0) submitObjects.push(...submitChildren[0]);
        if (submitChildren[1] === false) {
          valid = false;
          item.shouldDisplaySubmitError = true;
        }
        if (submitChildren[2] === true) empty = true;
        
      } else if (item.selectedValue) {
        submitObjects.push(
          {
            'ident': item.id.toString(),
            'description': item.question?.toString(),
            'type': 'string',
            'string_value': item.selectedValue.toString(),
        });
      }
    }
    return [[...submitObjects], valid, empty];
  }

  const handleSubmitForm = async () => {
    if (submitDisabled) {
      return;
    }
    setSubmitDisabled(true);

    const formTemplate = menuFormTemplate;
    const mandatoryFullfilled = true;
    const anyFormItemEmpty = false;
    const recordedValues = prepareChildrenForSubmit([...formItems], mandatoryFullfilled, anyFormItemEmpty);

    if (recordedValues[1] === false) {
      // Should show error
      setWarningDialogOpened(true);
      // Propagate error
      setFormMandatoryError(true);
      setSubmitDisabled(false);
      return;
    }

    if (recordedValues[2] === true) {
      // Display confirmation window
      if (recordedValues && recordedValues[0]) {
        setFormRecordedValues(recordedValues[0]);
      }
      setSubmitConfirmDialogOpened(true);
      setSubmitDisabled(false);
      return;
    }

    const body = {
        "form_name": formTemplate?.name,
        "form_description": formTemplate?.description,
        "form_id": filledFormId,
        "form_file_id": filledFormFileId,
        "form_data": {
          "data_records": recordedValues[0]
        }
    };

    await axios.post(`/api/Forms/SubmitForm`, body, { headers: {
      'Authorization': 'Bearer ' + jwt
    }});

    setAnchorEl(null);
    setOpenForm(false);
    setSubmitDisabled(false);
  };

  const onSubmitConfirm = async () => {
    if (submitDisabled) {
      return;
    }
    setSubmitDisabled(true);

    const formTemplate = menuFormTemplate;

    const body = {
      "form_name": formTemplate?.name,
      "form_description": formTemplate?.description,
      "form_id": filledFormId,
      "form_file_id": filledFormFileId,
      "form_data": {
        "data_records": formRecordedValues
      }
    };

    await axios.post(`/api/Forms/SubmitForm`, body, { headers: {
      'Authorization': 'Bearer ' + jwt
    }});

    setAnchorEl(null);
    setOpenForm(false);
    setSubmitConfirmDialogOpened(false);
    setSubmitDisabled(false);
  }

  const closeWarningDialog = () => {
    setWarningDialogOpened(false);
  }

  const closeSubmitConfirmDialog = () => {
    setSubmitConfirmDialogOpened(false);
  }

  // Anchor actions
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, formTemplate: FormTemplate) => {
    setAnchorEl(event.currentTarget);
    setMenuFormTemplate(formTemplate);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <DataGrid
              rows={formTemplates}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 20 },
                },
              }}
              pageSizeOptions={[5, 20, 40]}
              sx={{width: '100%'}}
            />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={createForm}>{t('forms.formsToCreate.actionMenu.createForm')}</MenuItem>
      </Menu>

      {/* Modal window */}
      <Dialog
        fullScreen={true}
        onClose={handleCloseFormModal}
        open={openForm}
        >
          <DialogTitle>
            {menuFormTemplate?.name}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseFormModal}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
            {/* <DescriptionSection description={menuFormTemplate?.description || ''} /> */}
          <DialogContent>
            <DialogContentText sx={{fontSize: '15px', paddingBottom: '8px', display: 'flex', justifyContent: 'flex-end'}}>
              <>
                {t('forms.formsToCreate.mandatoryInfoFirstPart', {lng: formLanguage})}<Box style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '4px', marginRight: '4px' }}>*</Box>{t('forms.formsToCreate.mandatoryInfoSecondPart', {lng: formLanguage})}
              </>
            </DialogContentText>
            {/* <Box sx={{display: 'flex', flexDirection: 'column'}}> */}
              <Stack spacing={2}>
                {formItems.map((formItem: IFormItem, index) => (
                <FormItem
                  formItem={formItem}
                  createMode={false}
                  key={formItem.id + '_' + index + '_item'}
                  setFormItem={(updatedFormItem: IFormItem) => {
                    const newFormItems = [...formItems].map((item) => {
                      if (item.id === formItem.id) {
                        return updatedFormItem;
                      } else return item;
                    });
                    setFormItems([...newFormItems]);
                  }}
                onDeleteFormItem={()=>{}}></FormItem>
              ))}
              </Stack>
            {/* </Box> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFormModal}>{t('forms.formsToCreate.close', {lng: formLanguage})}</Button>
            <Button onClick={handleSubmitForm} disabled={submitDisabled}>{t('forms.formsToCreate.submitForm', {lng: formLanguage})}</Button>
          </DialogActions>
      </Dialog>
      
      {/* Inform about empty values in questions dialog */}
      <Dialog
        open={warningDialogOpened}
        onClose={closeWarningDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('forms.formsToCreate.warningDialog.title', {lng: formLanguage})}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('forms.formsToCreate.warningDialog.text', {lng: formLanguage})}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeWarningDialog}>
            {t('forms.formsToCreate.warningDialog.close', {lng: formLanguage})}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm submit dialog */}
      <Dialog
        open={submitConfirmDialogOpened}
        onClose={closeSubmitConfirmDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('forms.formsToCreate.confirmationDialog.title', {lng: formLanguage})}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('forms.formsToCreate.confirmationDialog.text', {lng: formLanguage})}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeSubmitConfirmDialog}>
            {t('forms.formsToCreate.confirmationDialog.cancel', {lng: formLanguage})}
          </Button>
          <Button onClick={onSubmitConfirm} disabled={submitDisabled} color='error'>{t('forms.formsToCreate.confirmationDialog.submit', {lng: formLanguage})}</Button>
        </DialogActions>
      </Dialog>
    </>
  )
};

export default FormsToCreate;
