import React, { ReactElement } from "react";
import { useAppSelector } from "../../hooks";
import { Navigate } from "react-router-dom";

interface PrivateRouteProps {
    path: string;
    component: ReactElement<any,any>
}

const PrivateRoute: React.FC<PrivateRouteProps> = props => {
  const isAuthenticated = useAppSelector((state: any) => state.auth.isAuthenticated);

  return (isAuthenticated ? props.component : <Navigate replace to="/login" />);
};

export default PrivateRoute;
