import { Card } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { FormStateStats } from "../FormTemplateStatistics";
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";

interface Props {
    data: FormStateStats[];
    totalCount: number;
}

interface chartLabelObject {
  cx: number;
  cy: number,
  midAngle: number,
  innerRadius: number,
  outerRadius: number,
  percent: number,
  index: number
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;

const FormStateCountTile: React.FC<Props> = props => {
  
  const [data, setData] = useState<FormStateStats[]>([]);
  
  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  
  useLayoutEffect(() => {
    return () => {
        setData([]);
    }
  }, []);
  
  const renderCustomizedLabel = (value: chartLabelObject) => {
    const radius = value.innerRadius + (value.outerRadius - value.innerRadius) * 0.5;
    const x = value.cx + radius * Math.cos(-value.midAngle * RADIAN);
    const y = value.cy + radius * Math.sin(-value.midAngle * RADIAN);

    return (
      value.percent > 0 ?
      <text x={x} y={y} fill="white" textAnchor={x > value.cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(value.percent * props.totalCount).toFixed(0)}`}
      </text> : null
    );
  };

  return (
    data.length === 0 ? null : (
    <Card sx={{width: '300px', height: '300px', display: 'inline-block', justifyContent: 'center', alignItems: 'center'}}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={100} height={100}>
          <Pie
            animationDuration={2000}
            isAnimationActive={true}
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend></Legend>
        </PieChart>
      </ResponsiveContainer>
    </Card>
    )
  );
};

export default FormStateCountTile;
