import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks";
import axios from "axios";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, styled } from "@mui/material";
import { IFormItem } from "../survey/FormTemplateGenerator";
import FormItem from "../survey/questions/FormItem";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";


const FormsForReview: React.FC = () => {
  const { t } = useTranslation(['translation']);

  // enum FormItemStatus {
  //   Closed = 'CLOSED',
  //   Review = 'REVIEW',
  // }

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('forms.formsForReview.name'), flex: 3 },
    { field: 'description', headerName: t('forms.formsForReview.description'), flex: 5 },
    { field: 'status', headerName: t('forms.formsForReview.status'), flex: 2 },
  ];

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  interface IForm {
    name: string;
    description: string;
    _id: string;
    status: string;
  }

  // Form filling
  const [openForm, setOpenForm] = useState(false);
  const [formItems, setFormItems] = useState<IFormItem[]>([]);
  const [allForms, setAllForms] = useState<IForm[]>([]);
  // const [selectedForm, setSelectedForm] = useState(null);

  const jwt = useAppSelector((state: any) => state.auth.jwt);

  useEffect(() => {
    const getAllForms = async () => {
      const onboard = await axios.post(`/api/Forms/GetOnBoardForms`, {}, { headers: {
        'Authorization': 'Bearer ' + jwt
      }});
    
      let allOnBoardForms: IForm[] = [];
      
      if (onboard && onboard.data && onboard.data.forms) {
        allOnBoardForms = onboard.data.forms.map((form: IForm) => {
            form.status = t('forms.formsForReview.reviewUppercase');
          return form;
        });
      }
      
      const closedForms = await axios.post(`/api/Forms/GetClosedForms`, {}, { headers: {
        'Authorization': 'Bearer ' + jwt
      }});
      
      let allClosedForms: IForm[] = [];
      
      if (closedForms && closedForms.data && closedForms.data.forms) {
        allClosedForms = closedForms.data.forms.map((form: IForm) => {
            form.status = t('forms.formsForReview.closedUppercase');
          return form;
        });
      }

      setAllForms([...allOnBoardForms.concat(allClosedForms)]);
    };
    
    getAllForms();
  }, [jwt, t]);

  const handleCloseFormModal = () => {
    setOpenForm(false);
  };

  // const handleRowSelection = (params: GridRowParams) => {
  //   // setSelectedForm(params.row);

  //   // Open modal with user details
  //   setOpenForm(true);
  // }

  return (
    <>
    <DataGrid
            rows={allForms}
            columns={columns}
            // onRowClick={handleRowSelection}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 20 },
              },
            }}
            getRowId={(row)=>row._id}
            pageSizeOptions={[5, 20, 40]}
            sx={{width: '100%'}}
          />

      {/* Modal window for form detail TODO */}
      <BootstrapDialog
        onClose={handleCloseFormModal}
        aria-labelledby="customized-dialog-title"
        open={openForm}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          </DialogTitle>
          <DialogContentText>
          </DialogContentText>
          <DialogContent dividers>
            <Box sx={{width: '600px'}}>
              {formItems.map(formItem => (
              <FormItem
                formItem={formItem}
                createMode={false}
                key={formItem.id}
                setFormItem={(updatedFormItem: IFormItem) => {
                  setFormItems([...formItems].map((item) => {
                    if (item.id === formItem.id) {
                      return updatedFormItem;
                    } else return item;
                  }))
                }}
                onDeleteFormItem={()=>{}}></FormItem>
              ))} 
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFormModal}></Button>
          </DialogActions>
      </BootstrapDialog>
    </>
  )
};

export default FormsForReview;
