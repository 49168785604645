import { Box } from "@mui/material";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { IFormTemplate } from "../../formTemplate/FormTemplateManagement";
import { useAppSelector } from "../../../hooks";
import axios from "axios";
import TotalCountTile from "./statistics-tiles/TotalCountTile";
import FormStateCountTile from "./statistics-tiles/FormStateCountTile";
import { useTranslation } from "react-i18next";

interface Props {
  formTemplate?: IFormTemplate | null,
  onClose: () => void
}

interface FormTemplateInfo {
    total: number;
    from_index: number;
    to_index: number;
    forms: FormInfo[];
    status_code: number;
}

interface FormInfo {
    id: string;
    name: string;
    form_template_id: string;
    description: string;
    state: string;
    state_time: Date;
    user_ids: string[];
}

export interface FormStateStats {
    name: string;
    value: number;
}

const FormTemplateStatistics: React.FC<Props> = props => {
  const { t } = useTranslation(['translation']);
  const [formStatistics, setFormStatistics] = useState<FormTemplateInfo | null>(null);
  const jwt = useAppSelector((state: any) => state.auth.jwt);
  const [childKey, setChildKey] = useState(1);

  // Statistics
  const [total, setTotal] = useState<number>(0);
  // Does not make sense for now: const [totalUsers, setTotalUsers] = useState<number>(0);
  const [stateData, setStateData] = useState<FormStateStats[]>([]);
  
  useEffect(() => {
    setChildKey(prev => prev + 1);
  }, []);

  useEffect(() => {
    const getAllTemplates = async () => {
      if (!props.formTemplate || !props.formTemplate._id) {
        return;
      }
      const formStatisticsResponse = await axios.post(`/api/Forms/GetListOfForms`, {
        form_template_id: props.formTemplate?._id
      }, { headers: {
        'Authorization': 'Bearer ' + jwt
      }});

      if (formStatisticsResponse && formStatisticsResponse.data) {
        setFormStatistics(formStatisticsResponse.data as FormTemplateInfo);
      }
    };
    
    getAllTemplates();
  }, [jwt, props.formTemplate]);

  // Calculate statistics
  useEffect(() => {
    const calculateStats = async () => {
      if (!formStatistics) return;
      setTotal(formStatistics.total);

      const temporaryStateStats = formStatistics.forms.filter(form => form.state === 'temporary');
      const onBoardStateStats = formStatistics.forms.filter(form => form.state === 'onboard');
      const closedStateStats = formStatistics.forms.filter(form => form.state === 'closed');

      setStateData(
        [
            {name: t('templateStatistics.temporary'), value: temporaryStateStats.length},
            {name: t('templateStatistics.forReview'), value: onBoardStateStats.length},
            {name: t('templateStatistics.closed'), value: closedStateStats.length},
        ]
      );
    };
    calculateStats();
  }, [formStatistics, t]);
  
  useLayoutEffect(() => {
    return () => {
        setStateData([]);
    }
  }, []);

  return (
    <>
        <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '8px'}}>
            {/* Enter any statistics tile */}
            <TotalCountTile totalCount={total}></TotalCountTile>
            <FormStateCountTile key={childKey} totalCount={total} data={stateData}></FormStateCountTile>
        </Box>
    </>
  );
};

export default FormTemplateStatistics;
