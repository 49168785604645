import React, { Dispatch, SetStateAction, useState } from "react";
import { Rating } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import { NestedArrayOfString } from "../FormTemplateGenerator";

const labels: { [index: string]: string } = {
  0.5: 'Useless',
  1: 'Useless+',
  1.5: 'Poor',
  2: 'Poor+',
  2.5: 'Ok',
  3: 'Ok+',
  3.5: 'Good',
  4: 'Good+',
  4.5: 'Excellent',
  5: 'Excellent+',
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

interface RatingFormItemProps {
  setValue: Dispatch<SetStateAction<null | string | Array<NestedArrayOfString | string>>>,
  createMode: boolean
}

const RatingFormItem: React.FC<RatingFormItemProps> = props => {
  const [value, setValue] = useState<number | null>(null);
  // const [hover, setHover] = React.useState(-1);

  return (
    props.createMode === false ?
    <Rating
      name="hover-feedback"
      value={value}
      precision={0.5}
      getLabelText={getLabelText}
      onChange={(event, newValue) => {
        setValue(newValue);
        props.setValue(String(newValue));
      }}
      emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      
      /> : null
  );
};

export default RatingFormItem;
