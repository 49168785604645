import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import { IFormItem } from "../survey/FormTemplateGenerator";
import FormItem from "../survey/questions/FormItem";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormItemType } from "../survey/questions/IFormItem";

interface FormTemplate {
  name: string;
  description: string;
  id: string;
}

const FormForAnonymous: React.FC = () => {
  const { t, i18n } = useTranslation(['translation']);
  const [formLanguage, setFormLanguage] = useState(i18n.language);
  const [formRedirectURL, setFormRedirectURL] = useState('');
  
  // retrieve form template ID
  const { id } = useParams();

  const [errorMessage, setErrorMessage] = useState('');
  const [done, setDone] = useState(false);

  // Form filling
  const [activeForm, setActiveForm] = useState<FormTemplate | null>(null);

  const [filledFormFileId, setFilledFormFileId] = useState('');
  const [filledFormId, setFilledFormId] = useState('');
  const [formItems, setFormItems] = useState<IFormItem[]>([]);
  const [formRecordedValues, setFormRecordedValues] = useState<any>([]);
  
  const [, setFormMandatoryError] = useState(false);
  const [warningDialogOpened, setWarningDialogOpened] = useState(false);
  const [submitConfirmDialogOpened, setSubmitConfirmDialogOpened] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  useEffect(() => {
    const findTemplateWithProvidedID = async () => {
      // published only
      const publishedFormTemplatesResponse = await axios.post(`/api/Anonymous/GetPublishedFormTemplates`, {}, { headers: {}});
      let publishedFormTemplatesObject: FormTemplate[] = [];
      
      if (publishedFormTemplatesResponse && publishedFormTemplatesResponse.data && publishedFormTemplatesResponse.data.form_templates) {
        publishedFormTemplatesObject = publishedFormTemplatesResponse.data.form_templates;
        const formToCreate = publishedFormTemplatesObject.find(form => form.id === id);
        if (!formToCreate) {
          setErrorMessage(t('forms.formsForAnonymous.errorMessages.notExists', {lng: formLanguage}));
          setDone(true);
          return;
        }
        setActiveForm(formToCreate);
      } else {
        setErrorMessage(t('forms.formsForAnonymous.errorMessages.notExists', {lng: formLanguage}));
        setDone(true);
      }
    };
    
    findTemplateWithProvidedID();
  }, [id, t, formLanguage]);

  useEffect(() => {
    const loadForm = async () => {
      if (!activeForm) {
        return;
      }

      const body = {
        'form_template_id': activeForm.id,
        'form_name': activeForm.name,
        'form_description': activeForm.description
      };
      
      const createForm = await axios.post(`/api/Anonymous/CreateForm`, body, { headers: {}});

      setFilledFormFileId(createForm.data.form_file_id);
      setFilledFormId(createForm.data.form_id);

      // Download form file
      const formFile = await axios.get(`/api/Files/Download`, { params: {
        file_id: createForm.data.form_file_id
      }, headers: {
        'Content-Type': 'multipart/form-data'
      }});

      const downloadedTemplate = JSON.stringify(formFile.data);
      let downloadedForm;

      const dowloadedFormParsed = JSON.parse(downloadedTemplate);
      if (dowloadedFormParsed.form) {
        downloadedForm = dowloadedFormParsed.form;

        if (dowloadedFormParsed.language) {
          const language = dowloadedFormParsed.language;
          setFormLanguage(language);
        }

        if (dowloadedFormParsed.redirectURL) {
          const url = dowloadedFormParsed.redirectURL;
          setFormRedirectURL(url);
        }
      } else {
        downloadedForm = downloadedTemplate;
      }

      let parsedFormFile: IFormItem[] = JSON.parse(downloadedForm);
      setFormItems([...parsedFormFile]);
    };
    
    loadForm();
  }, [activeForm]);
  
  const markChildFormItem = (formItem: IFormItem, id: string) => {
    if (!formItem.children || formItem.children.length === 0) return;
    formItem.children = formItem.children.map(childFormItem => {
      if (childFormItem.id === id) {
        childFormItem.shouldDisplaySubmitError = true;
      } else if (childFormItem.children) {
        markChildFormItem(childFormItem, id);
      }
      return childFormItem;
    });
  }
  
  const prepareChildrenForSubmit = (children: IFormItem[], valid: boolean, empty: boolean): [any[], boolean, boolean] => {
    const submitObjects = [];
    for (const item of children) {
      if (item.additionalParams && item.additionalParams.mandatory && item.type !== FormItemType.questionContainer) {
        /** We need to check whether selected value is empty, however there is possibility for single choice,
         * which can be already defined as empty string, thus we need to ignore it.
        */
        if (((item.selectedValue?.length === 0 && item.selectedValue?.toString() === '' && item.type !== FormItemType.questionSingleChoice) || (item.selectedValue === null || item.selectedValue === undefined)) && (item.additionalParams.mandatory === true)) {
          valid = false;
          setFormItems(previousFormItems => {
            return [...previousFormItems].map((formItem) => {
              if (item.id === formItem.id) {
                formItem.shouldDisplaySubmitError = true;
              }
              if (formItem.children) {
                markChildFormItem(formItem, item.id);
              }
              return formItem;
            });
          });
        }
      } else if (((item.selectedValue?.toString() === '' && item.type !== FormItemType.questionSingleChoice) || (item.selectedValue === null || item.selectedValue === undefined)) && item.type !== FormItemType.customText) {
        empty = true;
      }
      if (item.type === FormItemType.questionContainer && item.children && item.children.length > 0) {
        const submitChildren = prepareChildrenForSubmit(item.children, valid, empty);
        if (submitChildren[0] && submitChildren[0].length > 0) submitObjects.push(...submitChildren[0]);
        if (submitChildren[1] === false) {
          valid = false;
          item.shouldDisplaySubmitError = true;
        }
        if (submitChildren[2] === true) empty = true;
        
      } else if (item.selectedValue) {
        submitObjects.push(
          {
            'ident': item.id.toString(),
            'description': item.question?.toString(),
            'type': 'string',
            'string_value': item.selectedValue.toString(),
        });
      }
    }
    return [[...submitObjects], valid, empty];
  }
  
  const handleSubmitForm = async () => {
    if (submitDisabled) {
      return;
    }
    setSubmitDisabled(true);

    const formTemplate = activeForm;
    const mandatoryFullfilled = true;
    const anyFormItemEmpty = false;
    const recordedValues = prepareChildrenForSubmit([...formItems], mandatoryFullfilled, anyFormItemEmpty);

    if (recordedValues[1] === false) {
      // Should show error
      setWarningDialogOpened(true);
      // Propagate error
      setFormMandatoryError(true);
      setSubmitDisabled(false);
      return;
    }

    if (recordedValues[2] === true) {
      // Display confirmation window
      if (recordedValues && recordedValues[0]) {
        setFormRecordedValues(recordedValues[0]);
      }
      setSubmitConfirmDialogOpened(true);
      setSubmitDisabled(false);
      return;
    }

    const body = {
        "form_name": formTemplate?.name,
        "form_description": formTemplate?.description,
        "form_id": filledFormId,
        "form_file_id": filledFormFileId,
        "form_data": {
          "data_records": recordedValues[0]
        }
    };

    const submitResult = await axios.post(`/api/Anonymous/SubmitForm`, body);
    
    // If valid
    if (submitResult.status !== 200) {
      setErrorMessage(t('forms.formsForAnonymous.notSubmitted', {lng: formLanguage}));
    }

    // If successfull navigate to redirect page
    if (submitResult.status === 200 && formRedirectURL.length > 0) { // && process.env.REACT_APP_COMPLETED_REDIRECT) {
      // Check if in iframe
      if (window && window.top) {
        window.top.location.href = formRedirectURL.includes('//') ? formRedirectURL : ('//' + formRedirectURL);
      } else {
        window.location.replace(formRedirectURL.includes('//') ? formRedirectURL : ('//' + formRedirectURL));
      }
    } else {
      setDone(true);
    }
    setSubmitDisabled(false);
  };

  const onSubmitConfirm = async () => {
    if (submitDisabled) {
      return;
    }
    setSubmitDisabled(true);
    const formTemplate = activeForm;

    const body = {
      "form_name": formTemplate?.name,
      "form_description": formTemplate?.description,
      "form_id": filledFormId,
      "form_file_id": filledFormFileId,
      "form_data": {
        "data_records": formRecordedValues
      }
    };

    const submitResult = await axios.post(`/api/Anonymous/SubmitForm`, body);
    
    // If valid
    if (submitResult.status !== 200) {
      setErrorMessage(t('forms.formsForAnonymous.notSubmitted', {lng: formLanguage}));
    }
    
    // If successfull navigate to redirect page
    if (submitResult.status === 200 && formRedirectURL.length > 0) { // && process.env.REACT_APP_COMPLETED_REDIRECT) {
      // Check if in iframe
      if (window && window.top) {
        window.top.location.href = formRedirectURL.includes('//') ? formRedirectURL : ('//' + formRedirectURL);
      } else {
        window.location.replace(formRedirectURL.includes('//') ? formRedirectURL : ('//' + formRedirectURL));
      }
    } else {
      setDone(true);
      setSubmitConfirmDialogOpened(false);
      setSubmitDisabled(false);
    }
  }
  
  const closeWarningDialog = () => {
    setWarningDialogOpened(false);
  }

  const closeSubmitConfirmDialog = () => {
    setSubmitConfirmDialogOpened(false);
  }

  return (
    <>
      {done ? (
      <Box sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <p style={{flex: '0 0 200px'}}>{errorMessage && errorMessage.length > 0 ? errorMessage : t('forms.formsForAnonymous.successMessage', {lng: formLanguage})}</p>
      </Box>
      ) :
      (<>
      <Dialog
        fullScreen={true}
        open={true}
        >
          <DialogTitle>
            {activeForm?.name}
          </DialogTitle>
            {/* <Typography sx={{ mt: 2, marginLeft: '20px' }}>                
              {activeForm?.description}
            </Typography> */}
          <DialogContent>
            <DialogContentText sx={{fontSize: '15px', paddingBottom: '8px', display: 'flex', justifyContent: 'flex-end'}}>
              <>
                {t('forms.formsToCreate.mandatoryInfoFirstPart', {lng: formLanguage})}<Box style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '4px', marginRight: '4px' }}>*</Box>{t('forms.formsToCreate.mandatoryInfoSecondPart', {lng: formLanguage})}
              </>
            </DialogContentText>
            <Stack spacing={2}>
                {formItems.map((formItem: IFormItem, index) => (
                <FormItem
                  formItem={formItem}
                  createMode={false}
                  key={formItem.id + '_' + index + '_item'}
                  setFormItem={(updatedFormItem: IFormItem) => {
                    const newFormItems = [...formItems].map((item) => {
                      if (item.id === formItem.id) {
                        return updatedFormItem;
                      } else return item;
                    });
                    setFormItems([...newFormItems]);
                  }}
                onDeleteFormItem={()=>{}}></FormItem>
              ))}
              </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmitForm} disabled={submitDisabled}>{t('forms.formsForAnonymous.submitForm', {lng: formLanguage})}</Button>
          </DialogActions>
      </Dialog>
      
      
      {/* Inform about empty values in questions dialog */}
      <Dialog
        open={warningDialogOpened}
        onClose={closeWarningDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('forms.formsToCreate.warningDialog.title', {lng: formLanguage})}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('forms.formsToCreate.warningDialog.text', {lng: formLanguage})}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeWarningDialog}>
            {t('forms.formsToCreate.warningDialog.close', {lng: formLanguage})}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirm submit dialog */}
      <Dialog
        open={submitConfirmDialogOpened}
        onClose={closeSubmitConfirmDialog}
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {t('forms.formsToCreate.confirmationDialog.title', {lng: formLanguage})}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('forms.formsToCreate.confirmationDialog.text', {lng: formLanguage})}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={closeSubmitConfirmDialog}>
            {t('forms.formsToCreate.confirmationDialog.cancel', {lng: formLanguage})}
          </Button>
          <Button onClick={onSubmitConfirm} disabled={submitDisabled} color='error'>{t('forms.formsToCreate.confirmationDialog.submit', {lng: formLanguage})}</Button>
        </DialogActions>
      </Dialog>
      </>)}
    </>
  )
};

export default FormForAnonymous;
