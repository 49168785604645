import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';

interface CustomTextFormItemProps {
  value: string,
  disabled?: boolean,
  setValue: Dispatch<SetStateAction<string>>
}

// TODO React quill is for some reason throwing deprecation warning
const CustomTextFormItem: React.FC<CustomTextFormItemProps> = props => {
  const [text, setText] = useState(props.value ?? '');

  // Update parent
  useEffect(() => {
    props.setValue(text);
  }, [text, props]);
    
  return (
    <>
      {!props.disabled ?
        <ReactQuill theme="snow" value={text} onChange={setText} style={{height: '250px', marginBottom: '32px', marginTop: '8px'}}/>
        : 
        (text ? 
        <div className="remove-padding">
          <ReactQuill
            value={text}
            readOnly={true}
            theme="bubble"
          />
        </div> : null)
      }
    </>           
  );
};

export default CustomTextFormItem;
