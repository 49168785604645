import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import { useAppSelector } from '../hooks';
import { UserRole } from '../store/constants/UserRole';
import { NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Flags from "country-flag-icons/react/3x2";

const Header: React.FC = () => {
  const isAuthenticated = useAppSelector((state: any) => state.auth.isAuthenticated);
  const userRole = useAppSelector((state: any) => state.auth.userRole);
  const { t, i18n } = useTranslation(['translation']);

  const FlagEn = Flags['GB'];
  const FlagDe = Flags['DE'];

  const changeLanguage = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
    localStorage.setItem('languageCode', languageCode);
  }

  const getLanguageIcon = (languageCode: string) => {
    
    switch (languageCode) {
      case 'en':
        return <FlagEn style={{width: '20px'}}/>;
      case 'de':
        return <FlagDe style={{width: '20px'}}/>;
      default:
        break;
    }
  }

  return isAuthenticated ? (
    <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
      <Container>
        <Navbar.Brand>PhiTech - Survey</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {/* <Nav.Link 
                as={Link}
                to="/dashboard">Dashboard</Nav.Link> */}
            {userRole === UserRole.Admin ? (<Nav.Link 
                as={Link}
                to="/templates">{t('header.templates')}</Nav.Link>) : null
            }
            <Nav.Link 
                as={Link}
                to="/forms">{t('header.forms')}</Nav.Link>
                
            {userRole === UserRole.Admin ? (<Nav.Link 
                as={Link}
                to="/userManagement">{t('header.userManagement')}</Nav.Link>) : null
            }
          </Nav>

          <Nav className="justify-content-end" style={{width: '58px',display: 'flex', justifyContent: 'center'}}>
            <NavDropdown
              title={
              <>
                {getLanguageIcon(i18n.language)}
                {/* {t('header.language.title')} */}
              </>}
              style={{width: '58px', display: 'flex', justifyContent: 'center'}}
            >
              <NavDropdown.Item style={{width: '58px', display: 'flex', justifyContent: 'center', height: '30px'}} onClick={() => {changeLanguage('en')}}>{getLanguageIcon('en')}</NavDropdown.Item>
              <NavDropdown.Item style={{width: '58px', display: 'flex', justifyContent: 'center', height: '30px'}} onClick={() => {changeLanguage('de')}}>{getLanguageIcon('de')}</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  ) : null;
}

export default Header;