import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, Stack, Switch, TextField } from "@mui/material";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { NestedArrayOfString } from "../FormTemplateGenerator";
import { AdditionalFormItemParams, FormItemOrientation } from "./FormItem";
import { useTranslation } from "react-i18next";

interface SingleChoiceFormItemProps {
    possibleValues: [string],
    setPossibleValues: Dispatch<SetStateAction<[string]>>,
    additionalParams: AdditionalFormItemParams,
    setAdditionalParams: Dispatch<SetStateAction<AdditionalFormItemParams>>,
    setValue: Dispatch<SetStateAction<null | string | Array<NestedArrayOfString | string>>>,
    setSelectedIndexes?: Dispatch<SetStateAction<number[]>>,
    createMode: boolean,
    language: string
}

const SingleChoiceFormItem: React.FC<SingleChoiceFormItemProps> = props => {
    const { t } = useTranslation(['translation']);

    // Selected options are selected indexes BASED ON POSSIBLE VALUES, not on options!
    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const [layout, setLayout] = useState<FormItemOrientation>(props.additionalParams.orientation ?? FormItemOrientation.horizontal);
    const [shouldContainOther, setShouldContainOther] = useState<boolean>(props.additionalParams.otherEnabled ?? false);
    const [randomOrder, setRandomOrder] = useState<boolean>(props.additionalParams.randomOrder ?? false);
    const [options, setOptions] = useState(props.possibleValues ? (randomOrder ? [...randomizeChoiceOrder(props.possibleValues)] : [...props.possibleValues]) : []);

    function randomizeChoiceOrder(options: string[]) {
        if (randomOrder === false) {
            return options;
        }

        let currentIndex = options.length,  randomIndex;
        while (currentIndex > 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [options[currentIndex], options[randomIndex]] = [
            options[randomIndex], options[currentIndex]];
        }
      
        return options;
      }

    const onTextChanged = (event: React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>, index: number) => {
        const newOptions = [...options] as [string];
        newOptions[index] = event.target.value;
        setOptions([...newOptions]);
        props.setPossibleValues([...newOptions]);
    }

    const addOption = () => {
        setOptions([...options,'']);
    };

    const deleteOption = (index: number) => {
        const updatedFields = [...options] as [string];
        
        if (updatedFields.length > 1) {
            updatedFields.splice(index, 1);
            setOptions(updatedFields);
            props.setPossibleValues(updatedFields);
        }
    };

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedOption((event.target as HTMLInputElement).value);
        props.setValue((event.target as HTMLInputElement).value);
    };

    const handleLayoutChange = (event: SelectChangeEvent) => {
        if ((event.target as HTMLInputElement).value === layout) {
            // do nothing
            return;
        }
        setLayout(FormItemOrientation[(event.target as HTMLInputElement).value as keyof typeof FormItemOrientation]);

        // Prepare new additional parameters
        const addParams = props.additionalParams;
        addParams.orientation = FormItemOrientation[(event.target as HTMLInputElement).value as keyof typeof FormItemOrientation];
        props.setAdditionalParams(structuredClone(addParams));
    };

    const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked === shouldContainOther) {
            // do nothing
            return;
        }
        setShouldContainOther(event.target.checked);
        
        const addParams = props.additionalParams;
        addParams.otherEnabled = event.target.checked;
        props.setAdditionalParams(structuredClone(addParams));
    };
    
    const handleRandomizeOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked === randomOrder) {
            // do nothing
            return;
        }
        setRandomOrder(event.target.checked);
        
        const addParams = props.additionalParams;
        addParams.randomOrder = event.target.checked;
        props.setAdditionalParams(structuredClone(addParams));
    };

    const onOtherTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        props.setValue((event.target as HTMLInputElement).value);
    };
    
    return (
        <>
          {props.createMode === true ?
            <Stack spacing={2}>
                <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <FormControl sx={{flex: '1', minWidth: '250px', display: 'block', paddingBottom: '8px', paddingTop: '8px'}}>
                        <InputLabel>Set radiobutton layout</InputLabel>
                        <Select
                            fullWidth
                                size='small'
                                label={t('formItems.singleChoice.setRadioButtonLayout')}
                                value={layout}
                                sx={{'marginBottom': '8px'}}
                                onChange={handleLayoutChange}
                            >
                                <MenuItem value={FormItemOrientation.horizontal}>{t('formItems.singleChoice.horizontal')}</MenuItem>
                                <MenuItem value={FormItemOrientation.vertical}>{t('formItems.singleChoice.vertical')}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormGroup sx={{flex: '1', minWidth: '250px', display: 'block', paddingLeft: '16px', paddingBottom: '8px', paddingTop: '8px'}}>
                        <FormControlLabel control={<Switch checked={shouldContainOther} value={shouldContainOther} onChange={handleOtherChange}/>} label={t('formItems.singleChoice.otherOptionEnabled')} />
                    </FormGroup>
                    <FormGroup sx={{flex: '1', minWidth: '250px', display: 'block', paddingLeft: '16px', paddingBottom: '8px', paddingTop: '8px'}}>
                        <FormControlLabel control={<Switch checked={randomOrder} value={randomOrder} onChange={handleRandomizeOrderChange}/>} label={t('formItems.singleChoice.randomOrder')} />
                    </FormGroup>
                </Box>
                <h4>{t('formItems.singleChoice.options')}</h4>
                {options.map((checkbox, index) => (
                    <Box key={index} sx={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <TextField
                            value={checkbox}
                            onChange={(e) => onTextChanged(e, index)}
                            size='small'
                            name={checkbox}
                            sx={{flex: '1'}}
                            > 
                        </TextField>
                        <IconButton
                                disabled={false}
                                onClick={() => {deleteOption(index)}}> 
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>
                ))}
                <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <IconButton onClick={addOption}>
                        <AddCircleOutlineRoundedIcon />
                    </IconButton>
                </Box>
            </Stack> :
            <RadioGroup
                value={selectedOption}
                onChange={handleOptionChange}
                row={(props.additionalParams.orientation && props.additionalParams.orientation === FormItemOrientation.vertical) ? false : true}
            >
                {options.map((radioButton, index) => (
                    <FormControlLabel key={index} control={
                        <Radio />
                } label={radioButton} value={radioButton} />
                ))}
                {props.additionalParams.otherEnabled === true ? 
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <FormControlLabel key={options.length + 1} control={
                        <Radio />
                    } label={t('formItems.singleChoice.other', {lng: props.language})} value={'Other'} />
                    {selectedOption === 'Other' ? <TextField
                            onChange={(e) => onOtherTextChanged(e)}
                            size='small'
                            sx={{flex: '1', minWidth: '100px'}}
                            > 
                    </TextField> : null }
                </Box> : null}
            </RadioGroup>
          }
        </>          
      );
}

export default SingleChoiceFormItem;
