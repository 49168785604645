import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { Box, FormControl, FormControlLabel, FormGroup, Stack, Switch, TextField } from "@mui/material";
import { FormItemIdContext, IFormItem, NestedArrayOfString } from "../FormTemplateGenerator";
import FormItem, { AdditionalFormItemParams } from "./FormItem";
import { FormItemType } from "./IFormItem";
import { useTranslation } from "react-i18next";

interface QuestionContainerFormItemProps {
    possibleValues: [string],
    setPossibleValues: Dispatch<SetStateAction<[string]>>,
    additionalParams: AdditionalFormItemParams,
    setAdditionalParams: Dispatch<SetStateAction<AdditionalFormItemParams>>,
    setValue: Dispatch<SetStateAction<null | string | Array<NestedArrayOfString | string>>>,
    setSelectedIndexes?: Dispatch<SetStateAction<[number]>>,
    children: IFormItem[],
    setChildren: Dispatch<SetStateAction<IFormItem[]>>,
    numOfDisplayedChildren?: number,
    setNumOfDisplayedChildren?: Dispatch<SetStateAction<number>>,
    createMode: boolean,
    language: string,
    shouldDisplayError?: boolean
}

const QuestionContainerFormItem: React.FC<QuestionContainerFormItemProps> = props => {
    const { t } = useTranslation(['translation']);
    const [randomOrder, setRandomOrder] = useState<boolean>(props.additionalParams.randomOrder ?? false);

    const [numberOfQuestions, setNumberOfQuestions] = useState<number>(props.children ? props.children.length - 1 : 0);
    const [numberOfDisplayedQuestions, setNumberOfDisplayedQuestions] = useState<number>(props.numOfDisplayedChildren ?? 0);
    
    const {formItemID} = useContext(FormItemIdContext);
    const [childFormItems, setChildFormItems] = useState<IFormItem[]>(props.children ? prepareFormItemsForDisplay(props.children) : [{ id: String(formItemID), type: FormItemType.customText, question: '', language: props.language}]);
    const [currentValue, setCurrentValue] = useState('');
    const [, setMaxLocalFormItemID] = useState(Number(formItemID));
    
    useEffect(() => {
        setChildFormItems(prevChildFormItems => {
            prevChildFormItems.map(formItem => {
                formItem.shouldDisplaySubmitError = props.shouldDisplayError;
                return formItem;
            });
            return [...prevChildFormItems];
        })
    }, [props.shouldDisplayError]);

    useEffect(() => {
        const updateChildren = () => {
            if (JSON.stringify(props.children) !== JSON.stringify(childFormItems)) {
                props.setChildren(childFormItems);
            }
        }

        const updateContainerValues = () => {
            const selectedChildValues = [];
            for (const item of childFormItems) {
                if (item.selectedValue) {
                    selectedChildValues.push(item.question + ": " + item.selectedValue);
                }
            }

            if (JSON.stringify(selectedChildValues) !== currentValue) {
                setCurrentValue(JSON.stringify(selectedChildValues));
                props.setValue([...selectedChildValues]);
            }
        }

        updateChildren();
        if (props.createMode === false) {
            updateContainerValues();
        }
    }, [childFormItems, props, currentValue]);

    useEffect(() => {
        setChildFormItems(prevChildFormItems => {
            prevChildFormItems.map(formItem => formItem.language = props.language);
            return [...prevChildFormItems];
        })
    }, [props.language]);
    
    useEffect(() => {
        const updateNumOfDisplayedQuestions = () => {
            if (props.numOfDisplayedChildren !== numberOfDisplayedQuestions) {
                if (props.setNumOfDisplayedChildren) {
                    props.setNumOfDisplayedChildren(numberOfDisplayedQuestions);
                }
            }
        }
        updateNumOfDisplayedQuestions();
    }, [numberOfDisplayedQuestions, props]);

    useEffect(() => {
        function onNumOfQuestionChanged(): void {
            setMaxLocalFormItemID(prev => {
                // Append / Pop questions
                setChildFormItems(prevFormItems => {
                    let currentId = Number(prev);
                    if (numberOfQuestions === prevFormItems.length - 1) return prevFormItems;

                    const validateFormItemID = (id: number, array: IFormItem[]): number => {
                        for (const item of array) {
                            if (item.id === String(id)) {
                                return validateFormItemID(id + 1, array);
                            } else if (item.children && item.children.length > 0) {
                                const idOfChildren = validateFormItemID(id, item.children);
                                if (idOfChildren !== id && idOfChildren > id) {
                                    return validateFormItemID(idOfChildren + 1, array);
                                }
                            }
                        }
                        return id
                    }

                    const formItems = [...prevFormItems];
                    currentId = validateFormItemID(currentId, formItems);
                    
                    while (formItems.length - 1 !== Number(numberOfQuestions)) {
                        if (formItems.length - 1 > Number(numberOfQuestions)) {
                            // Pop
                            formItems.pop();
                        } else {
                            formItems.push({ id: String(currentId), type: FormItemType.questionBoolean, value: '', language: props.language});
                            currentId = currentId + 1;
                        }
                    }
                    return [...formItems]
                });
                return Number(formItemID);
            });
        }

        // Do not update children while filling form
        if (props.createMode === true) {
            let timer = setTimeout(() => onNumOfQuestionChanged(), 1000);
            return () => {
                clearTimeout(timer);
            };
        } 
    }, [numberOfQuestions, formItemID, props.createMode, props.language]);

    // Randomize children order + remove first item which should be custom text
    function prepareFormItemsForDisplay (formItems: IFormItem[]): IFormItem[] {
        if (props.createMode) return [...formItems];
        if (randomOrder === false && numberOfQuestions === numberOfDisplayedQuestions) return [...formItems];
        
        const elements: IFormItem[] = [];
        const elementsIndexes: number[] = [];
        const randomizedFormItems = [...formItems];

        let containerText;
        if (randomizedFormItems[0].type === FormItemType.customText) {
            containerText = randomizedFormItems.shift();
        }

        const getRandomElement = (arr: IFormItem[]): [IFormItem[], number[]] => {
            if (elements.length < numberOfDisplayedQuestions && arr.length > 0) {
                let index = 0;
                if (arr.length > 1) index = Math.floor(Math.random() * arr.length);
                const element = arr.splice(index, 1)[0];
                if (element && !elements.find(formItem => formItem.id === element.id && formItem.question === element.question)) {
                    elements.push(element);
                    const origElement = formItems.find(item => item.id === element.id);
                    if (origElement) elementsIndexes.push(formItems.indexOf(origElement));
                }

                return getRandomElement(arr);
            } else {
                return [elements, elementsIndexes];
            }
        }

        const result = getRandomElement([...randomizedFormItems]);
        let arrToReturn = result[0];
        if (randomOrder === false) {
            const randomArray = [...result[0]];
            const randomArrayIndexes = [...result[1]];
            const orderedArray: IFormItem[] = [];
            // Reorder formItems to original order by the random indexes
            while (randomArray.length > 0) {
                const lowestIndex = randomArrayIndexes.indexOf(Math.min(...randomArrayIndexes));

                const element = randomArray.splice(lowestIndex, 1)[0];
                orderedArray.push(element);
                randomArrayIndexes.splice(lowestIndex, 1);
            }
            arrToReturn = [...orderedArray];
        }

        if (containerText) {
            arrToReturn.unshift(containerText);
            return arrToReturn;
        } else {
            return arrToReturn;
        }
    }

    const onNumberOfQuestionsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[0-9\b]+$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            setNumberOfQuestions(Number(event.target.value));
        
            // Check whether number of auestion is not bigger than displayed
            if (Number(event.target.value) < numberOfDisplayedQuestions) {
                setNumberOfDisplayedQuestions(Number(numberOfQuestions));
            }
        }
    }

    const onNumberOfDisplayedQuestionsChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[0-9\b]+$/;
        if (event.target.value === "" || regex.test(event.target.value)) {
            if (Number(event.target.value) <= numberOfQuestions) {
                setNumberOfDisplayedQuestions(Number(event.target.value));
                props.setValue(event.target.value);
            }
        } else {
            setNumberOfDisplayedQuestions(0);
        }
    }

    const deleteFormItem = (formItemID: string) => {
        setChildFormItems(previousFormItems => {
            return previousFormItems.filter(formItem => formItem.id !== formItemID);
        });
        setNumberOfQuestions(numberOfQuestions - 1);
    }
    
    const handleRandomizeOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked === randomOrder) {
            // do nothing
            return;
        }
        setRandomOrder(event.target.checked);
        
        const addParams = props.additionalParams;
        addParams.randomOrder = event.target.checked;
        props.setAdditionalParams(addParams);
    };

    return (
        <>
          {props.createMode ?
            <Stack spacing={2}>
                <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <FormControl sx={{flex: '1', minWidth: '200px', display: 'block', paddingBottom: '8px', paddingTop: '8px', marginRight: '8px'}}>
                        <TextField
                            label={t('formItems.questionContainer.numOfContainerQuestions')}
                            fullWidth
                            size='small'
                            type='number'
                            value={numberOfQuestions}
                            onChange={onNumberOfQuestionsChanged}
                        />
                    </FormControl>
                    <FormControl sx={{flex: '1', minWidth: '200px', display: 'block', paddingBottom: '8px', paddingTop: '8px', marginRight: '8px'}}>
                        <TextField
                            label={t('formItems.questionContainer.numOfDisplayedQuestions')}
                            fullWidth
                            size='small'
                            type='number'
                            value={numberOfDisplayedQuestions}
                            onChange={onNumberOfDisplayedQuestionsChanged}
                        />
                    </FormControl>
                    <FormGroup sx={{flex: '1', minWidth: '200px', display: 'block', paddingLeft: '16px', paddingBottom: '8px', paddingTop: '8px'}}>
                        <FormControlLabel control={<Switch checked={randomOrder} value={randomOrder} onChange={handleRandomizeOrderChange}/>} label={t('formItems.questionContainer.randomOrder')} />
                    </FormGroup>
                </Box>
                <h4>{t('formItems.questionContainer.items')}</h4>
                <Box sx={{'marginTop': '8px', 'width': '100%'}}>
                    <Stack spacing={2}>
                        {childFormItems.map((formItem: IFormItem, index) => {
                            return (
                                <Box key={formItem.id + '_' + index + '_box'}>
                                    <FormItem
                                        formItem={formItem}
                                        createMode={true}
                                        key={formItem.id}
                                        shouldDisplayError={props.shouldDisplayError}
                                        setFormItem={(updatedFormItem: IFormItem) => {
                                            setChildFormItems(prevChildFormItems => {
                                                return [...prevChildFormItems].map((item) => {
                                                    item.shouldDisplaySubmitError = props.shouldDisplayError;
                                                    if (item.id === formItem.id) {
                                                        return updatedFormItem;
                                                    } else return item;
                                                })
                                            })
                                        }}
                                        disableDelete={index === 0 ? true : false}
                                        onDeleteFormItem={()=>{deleteFormItem(formItem.id)}}></FormItem>
                                </Box>
                            )
                        })}
                    </Stack>
                </Box>
            </Stack> : 
            <Stack spacing={2}>
                {childFormItems.map((formItem: IFormItem, index) =>  (
                    <>
                    {index > 1 && <div
                        style={{
                            // Style to match the container border
                            width: '100%',
                            borderTop: '1px solid rgba(0, 0, 0, 0.3)',
                            // borderBottom: '0.3px solid rgba(0, 0, 0, 0.2)',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
                            marginTop: '40px',
                            marginBottom: '20px',
                        }}
                        ></div>}
                    <FormItem
                        formItem={formItem}
                        createMode={false}
                        key={formItem.id + '_' + index + '_item'}
                        setFormItem={(updatedFormItem: IFormItem) => {
                            setChildFormItems(prevChildFormItems => {
                                return [...prevChildFormItems].map((item) => {
                                    item.shouldDisplaySubmitError = props.shouldDisplayError;
                                    if (item.id === formItem.id) {
                                        return updatedFormItem;
                                    } else return item;
                                })
                            });
                        }}
                        shouldDisplayCard={false}
                        onDeleteFormItem={()=>{}}></FormItem>
                    </>
                    )
                )}
            </Stack>
         }
        </>          
    );
}

export default QuestionContainerFormItem;
