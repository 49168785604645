import React, { Dispatch, SetStateAction, useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { NestedArrayOfString } from "../FormTemplateGenerator";
import { useTranslation } from "react-i18next";

interface BooleanFormItemProps {
    createMode: boolean,
    language: string,
    setValue: Dispatch<SetStateAction<null | string | Array<NestedArrayOfString | string>>>
}

const BooleanFormItem: React.FC<BooleanFormItemProps> = props => {
    const [booleanValue, setBooleanValue] = useState<string | undefined>(undefined);
    const { t } = useTranslation(['translation']);

    const onBooleanValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setBooleanValue(event.target.value);
        props.setValue(event.target.value);
    }

    return (
        props.createMode === false ? 
            <>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={booleanValue}
                    onChange={onBooleanValueChanged}
                >
                    <FormControlLabel value="yes" control={<Radio />} label={t('formItems.boolean.yes',{lng: props.language})} />
                    <FormControlLabel value="no" control={<Radio />} label={t('formItems.boolean.no',{lng: props.language})} />
                </RadioGroup>
            </> : null
    )
};

export default BooleanFormItem;
