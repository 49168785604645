import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import FormsToCreate from "./FormsToCreate";
import FormsForReview from "./FormsForReview";
import { useTranslation } from "react-i18next";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{width: '100%'}}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Forms: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 'auto'}}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label={t('forms.availableForms')} {...a11yProps(0)} />
        <Tab label={t('forms.review')} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <FormsToCreate></FormsToCreate>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <FormsForReview></FormsForReview>
      </TabPanel>
    </Box>
  );
};

export default Forms;
