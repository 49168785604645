import React, { useState } from "react";
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import UserRoleDetail from "./UserRoleDetail";
import { useTranslation } from "react-i18next";


interface UserRoleListProps {
  roles: any[],
  reload: () => void
}

const UserRoleList: React.FC<UserRoleListProps> = props => {
  const { t } = useTranslation(['translation']);
  const [createRoleOpened, setCreateRoleOpened] = useState(false);
  const [roleDetailOpened, setRoleDetailOpened] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);

  const columns: GridColDef[] = [
    { field: 'name', headerName: t('userManagement.roleManagement.name'), flex: 3 },
    { field: 'description', headerName: t('userManagement.roleManagement.description'), flex: 7 },
  ];

  const openCreateNewRole = () => {
    setCreateRoleOpened(true);
  }
  
  const handleRowSelection = (params: GridRowParams) => {
    setSelectedRole(params.row);

    // Open modal with role details
    setRoleDetailOpened(true);
  }
  
  return (
    <>
      <div>
        <Button variant="contained" 
                onClick={openCreateNewRole}
                size="small"
                sx={{
                  'float': 'right', 'margin': '8px',
                }}>
          <AddIcon sx={{'marginRight': '8px'}}></AddIcon>{t('userManagement.roleManagement.addRole')}
        </Button>
      </div>
      <DataGrid
        rows={props.roles}
        columns={columns}
        onRowClick={handleRowSelection}
        initialState={{
        pagination: {
          paginationModel: { page: 0, pageSize: 20 },
        },
        }}
        pageSizeOptions={[5, 20, 40]}
        sx={{width: '100%'}}
      />
      
      {/* Modal window for role creation*/}
      {createRoleOpened ? <UserRoleDetail open={createRoleOpened} create={true} onClose={() => {setCreateRoleOpened(false)}} reload={props.reload}></UserRoleDetail> : null}
      
      {/* Modal window for role detail*/}
      {roleDetailOpened ? <UserRoleDetail open={roleDetailOpened} create={false} role={selectedRole} onClose={() => {setRoleDetailOpened(false)}} reload={props.reload}></UserRoleDetail> : null}
    </>
  )
};

export default UserRoleList;
