import React, { Dispatch, SetStateAction, useState } from "react";
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, Switch, TextField } from "@mui/material";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { NestedArrayOfString } from "../FormTemplateGenerator";
import { AdditionalFormItemParams, FormItemOrientation } from "./FormItem";
import { useTranslation } from "react-i18next";

interface MultipleChoiceFormItemProps {
    possibleValues: [string],
    setPossibleValues: Dispatch<SetStateAction<[string]>>,
    additionalParams: AdditionalFormItemParams,
    setAdditionalParams: Dispatch<SetStateAction<AdditionalFormItemParams>>,
    setValue: Dispatch<SetStateAction<null | string | Array<NestedArrayOfString | string>>>,
    // setSelectedIndexes?: Dispatch<SetStateAction<[number]>>,
    createMode: boolean,
    language: string
}

const MultipleChoiceFormItem: React.FC<MultipleChoiceFormItemProps> = props => {
    
    const { t } = useTranslation(['translation']);
    const [randomOrder, setRandomOrder] = useState<boolean>(props.additionalParams.randomOrder ?? false);
    const [options, setOptions] = useState(props.possibleValues ? (randomOrder ? [...randomizeChoiceOrder(props.possibleValues)] : [...props.possibleValues]) : []);
    // Selected options are selected indexes BASED ON POSSIBLE VALUES, not on options!
    // const [selectedOptions, setSelectedOptions] = useState<number[]>([]);)
    
    const [value, setValue] = useState<string[]>([]);
    const [otherChecked, setOtherChecked] = useState<boolean>(false);
    const [otherText, setOtherText] = useState<string>('');
    const [layout, setLayout] = useState<FormItemOrientation>(props.additionalParams.orientation ?? FormItemOrientation.horizontal);
    const [shouldContainOther, setShouldContainOther] = useState<boolean>(props.additionalParams.otherEnabled ?? false);
    
    function randomizeChoiceOrder(options: string[]) {
        if (randomOrder === false) {
            return options;
        }

        let currentIndex = options.length,  randomIndex;
        while (currentIndex > 0) {
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex--;
          [options[currentIndex], options[randomIndex]] = [
            options[randomIndex], options[currentIndex]];
        }
      
        return options;
      }

    const onTextChanged = (event: React.ChangeEvent<HTMLInputElement| HTMLTextAreaElement>, index: number) => {
        const newOptions = [...options] as [string];
        newOptions[index] = event.target.value;
        setOptions([...newOptions]);
        props.setPossibleValues([...newOptions]);
    }

    const addOption = () => {
        setOptions([...options,'']);
    };

    const deleteOption = (index: number) => {
        const updatedFields = [...options] as [string];
        
        if (updatedFields.length > 1) {
            updatedFields.splice(index, 1);
            setOptions(updatedFields);
            props.setPossibleValues(updatedFields);
        }
    };

    const onCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>, checkboxValue: string) => {
        const newSelectedValue = [...value];
        if (event.target.checked && newSelectedValue.indexOf(checkboxValue) < 0) {
            newSelectedValue.push(checkboxValue);
        } else {
            const indexForDeletion = newSelectedValue.indexOf(checkboxValue);
            if (indexForDeletion > -1) {
                newSelectedValue.splice(indexForDeletion, 1);
            }
        }
        setValue([...newSelectedValue]);
        if (props.setValue) {
            props.setValue([...newSelectedValue] as [string]);
        }
    }
    
    const handleLayoutChange = (event: SelectChangeEvent) => {
        if ((event.target as HTMLInputElement).value === layout) {
            // do nothing
            return;
        }
        setLayout(FormItemOrientation[(event.target as HTMLInputElement).value as keyof typeof FormItemOrientation]);

        // Prepare new additional parameters
        const addParams = props.additionalParams;
        addParams.orientation = FormItemOrientation[(event.target as HTMLInputElement).value as keyof typeof FormItemOrientation];
        props.setAdditionalParams(structuredClone(addParams));
    };

    const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked === shouldContainOther) {
            // do nothing
            return;
        }
        setShouldContainOther(event.target.checked);
        
        const addParams = props.additionalParams;
        addParams.otherEnabled = event.target.checked;
        props.setAdditionalParams(structuredClone(addParams));
    };
    
    const handleRandomizeOrderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked === randomOrder) {
            // do nothing
            return;
        }
        setRandomOrder(event.target.checked);
        
        const addParams = props.additionalParams;
        addParams.randomOrder = event.target.checked;
        props.setAdditionalParams(structuredClone(addParams));
    };
    
    const onOtherTextChanged = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        // Remove old
        const newSelectedValue = [...value];
        if (newSelectedValue.indexOf(otherText) > -1) {
            newSelectedValue.splice(newSelectedValue.indexOf(otherText), 1);
        }
        
        if (otherChecked) {
            // Add
            setOtherText((event.target as HTMLInputElement).value);
            newSelectedValue.push((event.target as HTMLInputElement).value);
        }

        setValue([...newSelectedValue]);
        if (props.setValue) {
            props.setValue([...newSelectedValue] as [string]);
        }
    };

    const onOtherChecked = (event:  React.ChangeEvent<HTMLInputElement>) => {
        setOtherChecked(event.target.checked);
        if (event.target.checked === false) {
            const newSelectedValue = [...value];
            if (newSelectedValue.indexOf(otherText) > -1) {
                newSelectedValue.splice(newSelectedValue.indexOf(otherText), 1);
            }
            setValue([...newSelectedValue]);
            if (props.setValue) {
                props.setValue([...newSelectedValue] as [string]);
            }
            setOtherText('');
        }
    };



    return (
        <>
          {props.createMode ?
            <Stack spacing={2}>
                <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <FormControl sx={{flex: '1', minWidth: '250px', display: 'block', paddingBottom: '8px', paddingTop: '8px'}}>
                        <InputLabel>{t('formItems.multipleChoice.setCheckboxLayout')}</InputLabel>
                        <Select
                            fullWidth
                                size='small'
                                label={t('formItems.multipleChoice.setCheckboxLayout')}
                                value={layout}
                                sx={{'marginBottom': '8px'}}
                                onChange={handleLayoutChange}
                            >
                                <MenuItem value={FormItemOrientation.horizontal}>{t('formItems.multipleChoice.horizontal')}</MenuItem>
                                <MenuItem value={FormItemOrientation.vertical}>{t('formItems.multipleChoice.vertical')}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormGroup sx={{flex: '1', minWidth: '250px', display: 'block', paddingLeft: '16px', paddingBottom: '8px', paddingTop: '8px'}}>
                        <FormControlLabel control={<Switch checked={shouldContainOther} value={shouldContainOther} onChange={handleOtherChange}/>} label={t('formItems.multipleChoice.otherOptionEnabled')}/>
                    </FormGroup>
                    <FormGroup sx={{flex: '1', minWidth: '250px', display: 'block', paddingLeft: '16px', paddingBottom: '8px', paddingTop: '8px'}}>
                        <FormControlLabel control={<Switch checked={randomOrder} value={randomOrder} onChange={handleRandomizeOrderChange}/>} label={t('formItems.multipleChoice.randomOrder')} />
                    </FormGroup>
                </Box>
                <h4>{t('formItems.multipleChoice.options')}</h4>
                {options.map((checkbox, index) => (
                    <Box key={index} sx={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                        <TextField
                            value={checkbox}
                            onChange={(e) => onTextChanged(e, index)}
                            size='small'
                            name={checkbox}
                            sx={{flex: '1'}}
                            > 
                        </TextField>
                        <IconButton
                                disabled={false}
                                onClick={() => {deleteOption(index)}}> 
                            <HighlightOffIcon />
                        </IconButton>
                    </Box>
                ))}
                <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                    <IconButton onClick={addOption}>
                        <AddCircleOutlineRoundedIcon />
                    </IconButton>
                </Box>
            </Stack> : 
            <Stack direction={props.additionalParams.orientation && props.additionalParams.orientation === FormItemOrientation.vertical ? 'column' : 'row'} spacing={0}>
                {options.map((checkbox, index) => (
                    <FormControlLabel key={index} control={
                        <Checkbox onChange={(event) => {onCheckBoxChange(event, checkbox)}} checked={value.indexOf(checkbox) > -1}/>
                    } label={checkbox} />
                ))}
                {props.additionalParams.otherEnabled === true ? 
                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                    <FormControlLabel key={options.length + 1} control={
                        <Checkbox onChange={(event) => {onOtherChecked(event)}} checked={otherChecked}/>
                    } label={t('formItems.multipleChoice.other', {lng: props.language})} value={'Other'} />
                    {otherChecked === true ? <TextField
                            onChange={(e) => onOtherTextChanged(e)}
                            value={otherText}
                            size='small'
                            sx={{flex: '1', minWidth: '100px'}}
                            > 
                    </TextField> : null }
                </Box> : null}
            </Stack>
          }
        </>          
      );
}

export default MultipleChoiceFormItem;
