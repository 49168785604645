import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';

import './FormTemplateGenerator.css'
import FormItem, { AdditionalFormItemParams } from './questions/FormItem';
import { Box, Button, Menu, MenuItem, MenuProps, Stack, TextField, Typography, IconButton, alpha, styled, Select, SelectChangeEvent } from '@mui/material';
import { FormItemType } from './questions/IFormItem';
import { Add } from '@mui/icons-material';
import axios from 'axios';
import { IFormTemplate } from '../formTemplate/FormTemplateManagement';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { useTranslation } from 'react-i18next';
import validator from 'validator';
import Flags from "country-flag-icons/react/3x2";

export interface NestedArrayOfString extends Array<NestedArrayOfString | string> {
}

export interface IFormItem {
    id: string,
    type: FormItemType,
    question?: string,
    value?: string,
    possibleValue?: [string],
    additionalParams?: AdditionalFormItemParams,
    selectedValue?: null | string | Array<NestedArrayOfString | string>,
    // selectedIndexes?: [number],
    children?: IFormItem[],
    numOfDisplayedChildren?: number,
    shouldDisplaySubmitError?: boolean,
    language: string,
}

interface FormGeneratorProps {
  formTemplate?: IFormTemplate | null,
  onSubmit: () => void
}

// For demo purposes / TODO refactor
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

interface formItemIdContextObject {
  formItemID: number
}

export const FormItemIdContext = React.createContext<formItemIdContextObject>({
  formItemID: 0
});

const FormGenerator: React.FC<FormGeneratorProps> = props => {
  const { t, i18n } = useTranslation(['translation']);
  const [templateName, setTemplateName] = useState<string>(props.formTemplate ? props.formTemplate.name : '');
  const [templateLanguage, setTemplateLanguage] = useState(i18n.language);
  const [templateDescription, setTemplateDescription] = useState<string>(props.formTemplate ? props.formTemplate.description : '');
  const [formItems, setFormItems] = useState<IFormItem[]>([]);
  const [formItemID, setFormItemID] = useState<number>(1);
  const [addAtIndex, setAddAtIndex] = useState<number | null>(null);
  const [redirectURL, setRedirectURL] = useState<string>('');

  // Input error handlers
  const [nameErrorVisible, setNameErrorVisible] = useState(false);
  const [descriptionErrorVisible, setDescriptionErrorVisible] = useState(false);
  const [redirectURLErrorVisible, setRedirectURLErrorVisible] = useState(false);

  // Add new button
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openAddNewMenu = Boolean(anchorEl);

  // t('Placeholder', { lng: 'de' }) // For translation to german
  const FlagEn = Flags['GB'];
  const FlagDe = Flags['DE'];

  const jwt = useAppSelector((state: any) => state.auth.jwt);

  const getLanguageIcon = (languageCode: string) => {
    switch (languageCode) {
      case 'en':
        return <FlagEn style={{width: '20px'}}/>;
      case 'de':
        return <FlagDe style={{width: '20px'}}/>;
      default:
        break;
    }
  }

  // Load form items if form template exists
  useEffect(() => {
    const getFormTemplateData = async () => {
      if (!props.formTemplate || !props.formTemplate.current_file_id) {
        return;
      }

      // Download form file
      const formFile = await axios.get(`/api/Files/Download`, { params: {
        file_id: props.formTemplate?.current_file_id
      }, headers: {
        'Authorization': 'Bearer ' + jwt,
        'Content-Type': 'multipart/form-data'
      }});

      const downloadedTemplate = JSON.stringify(formFile.data);
      let downloadedForm;
      const dowloadedFormParsed = JSON.parse(downloadedTemplate);
      if (dowloadedFormParsed.form) {
        downloadedForm = dowloadedFormParsed.form;

        if (dowloadedFormParsed.language) {
          const language = dowloadedFormParsed.language;
          setTemplateLanguage(language);
        }

        if (dowloadedFormParsed.redirectURL) {
          const url = dowloadedFormParsed.redirectURL;
          setRedirectURL(url);
        }
      } else {
        downloadedForm = downloadedTemplate;
      }

      let parsedFormFile: IFormItem[] = JSON.parse(downloadedForm);
      if (parsedFormFile.length > 0) {
        setFormItems([...parsedFormFile]);
      }
    };
    
    getFormTemplateData();
  }, [jwt, props.formTemplate]);

  useEffect(() => {
    function findHighestFormItemID(): number {
      if (!formItems || formItems.length < 1) {
        return 1;
      }
      let highestId = 1;
      for (const formItem of formItems) {
        const formItemMaxId = findMaxIdInChildFormItems(formItem);
        if (formItemMaxId > highestId) {
          highestId = formItemMaxId;
        }
      }

      return highestId;
    }

    function findMaxIdInChildFormItems(formItem: IFormItem): number {
                   
      if (!formItem.children || formItem.children.length < 1) return Number(formItem.id);

      let max = Number(formItem.id);
      for (const childFormItem of formItem.children) {
        const highestIdInChildFormItem = findMaxIdInChildFormItems(childFormItem);
        if (highestIdInChildFormItem > max) {
          max = highestIdInChildFormItem;
        }
      }

      return max;
    }
    if (formItems.length > 0) {
      setFormItemID(findHighestFormItemID() + 1);
    }
  }, [formItems]);

  const addNewFormItem = () => {
    if (addAtIndex === null) {
      setFormItems(previousFormItems => [
        ...previousFormItems,
        { id: String(formItemID), type: FormItemType.questionBoolean, question: '', language: templateLanguage}
      ]);
    } else {
      setFormItems(previousFormItems => {
        const newFormItems = [...previousFormItems];
        newFormItems.splice(addAtIndex + 1, 0, 
        { id: String(formItemID), type: FormItemType.questionBoolean, question: '', language: templateLanguage});
        return newFormItems;
      });
    }
    setAddAtIndex(null);
    setFormItemID(formItemID + 1);
    handleAddNewClose();
  }

  const addNewTextFormItem = () => {
    if (addAtIndex === null) {
      setFormItems(previousFormItems => [
        ...previousFormItems,
        { id: String(formItemID), type: FormItemType.customText, value: '', language: templateLanguage}
      ]);
    } else {
      setFormItems(previousFormItems => {
        const newFormItems = [...previousFormItems];
        newFormItems.splice(addAtIndex + 1, 0,
        { id: String(formItemID), type: FormItemType.customText, value: '', language: templateLanguage});
        return newFormItems;
      });
    }
    setAddAtIndex(null);
    setFormItemID(formItemID + 1);
    handleAddNewClose();
  }

  const addNewFormItemAfterIndex = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setAddAtIndex(index);
    setAnchorEl(event.currentTarget);
  }

  const deleteFormItem = (formItemID: string) => {
    setFormItems(previousFormItems => {
      return previousFormItems.filter(formItem => formItem.id !== formItemID);
    });
  };

  const uploadTemplateQuestions = async () => {
    // Prepare JSON body of the template from the questions
    const formData = new FormData();
    const templateData = {
      'language': templateLanguage,
      'redirectURL': redirectURL,
      'form': JSON.stringify(formItems)
    }
    const templateBlob = new Blob([JSON.stringify(templateData)], {type: 'text/plain'}); //pass data from localStorage API to blob
    formData.append('file', templateBlob);
    
    const { data } = await axios.post(`/api/Files/Upload`, formData, { headers: {
      'Authorization': 'Bearer ' + jwt,
      'Content-Type': 'multipart/form-data'
    }});

    // '3171a1e5469249fca5cda21e59a69e41'
    // ''1aa3f6802d034d4eb009537e2ebb0e15'' - form template ID
    if (data.file_id) {
      return data.file_id;
    }
    return '';
  }

  const handleSubmit = async () => {
    // Do this for template edit too (file cannot be replaced)
    const fileID = await uploadTemplateQuestions();

    if (fileID.length === 0) {
      return;
    }

    if (!props.formTemplate) {
      // If form template was not loaded, create new
      await axios.post(`/api/FormTemplates/createformtemplate`, {
        'name': templateName,
        'description': templateDescription,
        'file_id': fileID
      }, { headers: {
        'Authorization': 'Bearer ' + jwt
      }});
    } else {
      // Otherwise update it's dataset

      // New file id
      await axios.post(`/api/FormTemplates/UpdateFormTemplateFile`, {
        'form_template_id': props.formTemplate._id,
        'new_file_id': fileID
      }, { headers: {
        'Authorization': 'Bearer ' + jwt
      }});

      // New values for description
      await axios.post(`/api/FormTemplates/UpdateFormTemplate`, {
        'name': templateName,
        'description': templateDescription,
        'form_template_id': props.formTemplate._id
      }, { headers: {
        'Authorization': 'Bearer ' + jwt
      }});
    }
    
    if (props.onSubmit) {
      props.onSubmit();
    }
  };
  
  const handleAddNewClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleAddNewClose = () => {
    setAnchorEl(null);
  };

  const handleTemplateLanguageChange = (event: SelectChangeEvent) => {
    setTemplateLanguage(event.target.value);
    setFormItems(previousFormItems => {
      previousFormItems.map(item => {item.language = event.target.value; return item;});
      return [...previousFormItems];
    });
  }

  return (
    <>
      <div>
        <Typography id='modal-modal-title' variant='h6' component='h2' sx={{'float': 'left'}}>
          {''}
        </Typography>
        <Button variant='contained' onClick={handleSubmit} size='medium' sx={{
                'float': 'left', 'marginBottom': '16px'
              }}>{t('templateGenerator.saveTemplate')}</Button>
      </div>
      <div className='form-template-generator-container'>  
        <TextField
                fullWidth
                id='template-name'
                label={t('templateGenerator.templateName')}
                value={templateName}
                error={nameErrorVisible && templateName.length < 1}
                helperText={(nameErrorVisible && templateName.length < 1) ? t('templateGenerator.missingName') : ''}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                  setTemplateName(event.target.value);
                }}
                onFocus={
                  ()=>{
                    setNameErrorVisible(true);
                  }
                }
            />
        <TextField
                fullWidth
                id='template-description'
                label={t('templateGenerator.templateDescription')}
                value={templateDescription}
                error={descriptionErrorVisible && templateDescription.length < 1}
                helperText={(descriptionErrorVisible && templateDescription.length < 1) ? t('templateGenerator.missingDescription') : ''}
                sx={{'marginTop': '8px'}}
                onChange={
                    (event: React.ChangeEvent<HTMLInputElement>) => {
                    setTemplateDescription(event.target.value);
                  }}
                onFocus={
                  ()=>{
                    setDescriptionErrorVisible(true);
                  }
                }
            />
        
        <Select
          fullWidth
          size='small'
          // label={t('templateGenerator.templateLanguage')}
          value={templateLanguage}
          sx={{'marginBottom': '8px'}}
          onChange={handleTemplateLanguageChange}
        >
            <MenuItem value={'en'}>{<Box sx={{display: 'flex'}}>{getLanguageIcon('en')}<Box sx={{paddingLeft: '8px'}}>{t('templateGenerator.local.english')}</Box></Box>}</MenuItem>
            <MenuItem value={'de'}>{<Box sx={{display: 'flex'}}>{getLanguageIcon('de')}<Box sx={{paddingLeft: '8px'}}>{t('templateGenerator.local.german')}</Box></Box>}</MenuItem>
        </Select>
        <TextField
                fullWidth
                id='url-redirect'
                label={t('templateGenerator.redirectURL')}
                value={redirectURL}
                error={redirectURLErrorVisible && redirectURL.length > 0}
                helperText={(redirectURLErrorVisible && redirectURL.length > 0) ? t('templateGenerator.redirectURLNotValid') : ''}
                onChange={
                  (event: React.ChangeEvent<HTMLInputElement>) => {
                  setRedirectURL(event.target.value);
                  // Check whether provided url is valid
                  if (validator.isURL(event.target.value)) {
                    setRedirectURLErrorVisible(false);
                  } else {
                    setRedirectURLErrorVisible(true);
                  }
                }}
            />
        <FormItemIdContext.Provider value={{ formItemID: formItemID }}>
          <Box sx={{'marginTop': '8px', 'width': '100%'}} key='provider-box'>
            <Stack spacing={2}>
              <Box key={ formItemID + '_first_add_button'} sx={{width: '100%', display: 'flex', flexDirection: 'column', marginTop: '16px', alignItems: 'center', justifyContent: 'center'}}> 
                <IconButton onClick={(event) => {addNewFormItemAfterIndex(event, -1);}}>
                  <AddCircleOutlineRoundedIcon />
                </IconButton>
              </Box>
              {formItems.map((formItem, index) => { return (
                <Box key={formItem.id + '_' + index + '_container'}>
                  <Box key={formItem.id + '_' + index + '_box'}>
                    <FormItem
                      formItem={formItem}
                      createMode={true}
                      key={formItem.id + '_' + '_formItem'}
                      setFormItem={(updatedFormItem: IFormItem) => {
                        const newFormItems = [...formItems].map((item) => {
                          if (item.id === formItem.id) {
                            return updatedFormItem;
                          } else return item;
                        });
                        setFormItems([...newFormItems]);
                      }}
                    onDeleteFormItem={deleteFormItem}></FormItem>
                  </Box>
                  
                  <Box key={formItem.id + '_' + index + '_addButton'}
                    sx={{width: '100%', display: 'flex', flexDirection: 'column', marginTop: '16px', alignItems: 'center', justifyContent: 'center'}}> 
                    {/* TBD height: '2px', backgroundColor: 'grey', marginTop: '24px', zIndex: '-1', marginBottom: '24px',  */}
                    <IconButton onClick={(event) => {addNewFormItemAfterIndex(event, index);}}>
                      <AddCircleOutlineRoundedIcon />
                    </IconButton>
                  </Box>
                </Box>
              )})}
            </Stack>
            <Box>
              <Button
                aria-controls={openAddNewMenu ? 'demo-customized-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={openAddNewMenu ? 'true' : undefined}
                variant='contained'
                disableElevation
                onClick={handleAddNewClick}
                // endIcon={<KeyboardArrowDownIcon />}
                startIcon={<Add />}
                sx={{
                  'float': 'right', 'margin': '8px',
                }}
              >
                {t('templateGenerator.addNewItem')}
              </Button>
            </Box>
          </Box>
        </FormItemIdContext.Provider>
      </div>

      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={openAddNewMenu}
        onClose={handleAddNewClose}
      >
        <MenuItem onClick={addNewFormItem} disableRipple>
          {t('templateGenerator.question')}
        </MenuItem>
        {/* <MenuItem onClick={addNewContainerFormItem} disableRipple>
          Question container
        </MenuItem> */}
        <MenuItem onClick={addNewTextFormItem} disableRipple>
          {/* <FileCopyIcon /> */}
          {t('templateGenerator.customText')}
        </MenuItem>
        {/* <Divider sx={{ my: 0.5 }} /> */}
      </StyledMenu>
    </>);
};

export default FormGenerator;
