import React, { useEffect, useRef, useState } from "react";
import { Box, Card, CardContent, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Switch, TextField, Typography } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import BooleanFormItem from "./BooleanFormItem";
import { FormItemType } from "./IFormItem";
import TextFormItem from "./TextFormItem";
import RatingFormItem from "./RatingFormItem";
import { IFormItem } from "../FormTemplateGenerator";
import CustomTextFormItem from "./CustomTextFormItem";
import NumberFormItem from "./NumberFormItem";
import MultipleChoiceFormItem from "./MultipleChoiceFormItem";
import SingleChoiceFormItem from "./SingleChoiceFormItem";
import QuestionContainerFormItem from "./QuestionContainerFormItem";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

export interface FormItemProps {
    formItem: IFormItem,
    createMode: boolean,
    visible?: boolean,
    setFormItem: (updatedFormItem: IFormItem) => void,
    onDeleteFormItem: (id: string) => void;
    shouldDisplayError?: boolean;
    shouldDisplayCard?: boolean;
    disableDelete?: boolean;
}

export enum FormItemOrientation {
  vertical = 'vertical',
  horizontal = 'horizontal'
}

export interface AdditionalFormItemParams {
  mandatory?: boolean,
  randomOrder?: boolean,
  orientation?: FormItemOrientation,
  otherEnabled?: boolean,
  minValue?: number,
  maxValue?: number,
  stepValue?: number,
  unit?: string,
  numberFormItemType?: string
}

const FormItem: React.FC<FormItemProps> = props => {
    const { ref, inView } = useInView({
      /* Optional options */
      threshold: 0,
    });
    const formItemMounted = useRef(false);
  
    const { t, i18n } = useTranslation(['translation']);
    const [formItemValue, setFormItemValue] = useState(props.formItem);
    const [shouldRender, setShouldRender] = useState(false);
    const [formItemChildren, setFormItemChildren] = useState<IFormItem[]>(structuredClone(props.formItem.children) as IFormItem[]);
    const [displaySubmitError, setDisplaySubmitError] = useState(props.formItem.shouldDisplaySubmitError);
    const [numOfDisplayedChildren, setNumOfDisplayedChildren] = useState<number>(props.formItem.numOfDisplayedChildren ?? 0);
    
    const formItemId = props.formItem.id;
    const createMode = props.createMode;

    const [questionText, setQuestionText] = useState(props.formItem.question ?? '');
    const [valueText, setValueText] = useState(props.formItem.value ?? '');
    const [formItemType, setFormItemType] = useState(props.formItem.type ?? null);
    const [mandatory, setMandatory] = useState(props.formItem.additionalParams && props.formItem.additionalParams.mandatory ? props.formItem.additionalParams.mandatory : false);
    const [selectedValue, setSelectedValue] = useState(props.formItem.selectedValue ?? null);
    const [possibleValues, setPossibleValues] = useState(props.formItem.possibleValue as [string] ?? ['']);
    const [additionalParams, setAdditionalParams] = useState<AdditionalFormItemParams>((props.formItem.additionalParams && Object.prototype.toString.call(props.formItem.additionalParams) !== '[object Array]') ? structuredClone(props.formItem.additionalParams) : {});

    const onQuestionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuestionText(event.target.value);
    }

    const handleFormItemChange = (event: SelectChangeEvent) => {
      setFormItemType(event.target.value as FormItemType);
      if (event.target.value as FormItemType === FormItemType.questionContainer) {
        // remove mandatory parameter (set to false)
        setMandatory(false);
      }
    };

    // Updater for form item value
    useEffect(() => {
      if (formItemMounted.current === false) {
        return;
      }
      // Change detector
      if (JSON.stringify(props.formItem) === JSON.stringify(formItemValue)) {
        return;
      }
      props.setFormItem(formItemValue);
    }, [formItemValue, props]);

    useEffect(() => {
      if (inView === true) {
        setShouldRender(true);
      }
    }, [inView]);

    useEffect(() => {
      if (props.shouldDisplayError === true) {
        setDisplaySubmitError(true);
      }
    }, [props.shouldDisplayError]);
    
    useEffect(() => {
      if (formItemMounted.current === false) {
        formItemMounted.current = true;
        return;
      }
      setFormItemValue((prevFormItemValue: IFormItem) => {
        const updatedFormItem = structuredClone(prevFormItemValue);
        updatedFormItem.question = questionText;
        
        if (formItemType) {
          updatedFormItem.type = formItemType
        }

        if (valueText.length >= 0) {
          updatedFormItem.value = valueText;
        }

        if (!createMode) {
          if (selectedValue && typeof selectedValue !== "string") {
            selectedValue.sort()
          }
          updatedFormItem.selectedValue = selectedValue;
        }

        if (possibleValues.length > 0) {
          updatedFormItem.possibleValue = [...possibleValues];
        }

        if (createMode && additionalParams && (JSON.stringify(updatedFormItem.additionalParams) !== JSON.stringify(additionalParams))) {
          updatedFormItem.additionalParams = structuredClone(additionalParams);
        }
        
        if (formItemChildren && formItemChildren.length > 0) {
          updatedFormItem.children = [...formItemChildren] as IFormItem[];
        }
        
        if (formItemChildren && formItemChildren.length === 0 && formItemType === FormItemType.questionContainer) {
          updatedFormItem.children = [] as IFormItem[];
        }

        if (numOfDisplayedChildren > 0 && formItemType === FormItemType.questionContainer) {
          updatedFormItem.numOfDisplayedChildren = numOfDisplayedChildren;
        }
        
        return updatedFormItem;
      });
    }, [questionText, formItemType, selectedValue, valueText, possibleValues, additionalParams, formItemId, createMode, formItemChildren, numOfDisplayedChildren, mandatory]);

    useEffect(() => {
      if (props.formItem.shouldDisplaySubmitError === true) {
        setDisplaySubmitError(true);
      }
    }, [props.formItem.shouldDisplaySubmitError])

    const handleMandatoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked === mandatory) {
          // do nothing
          return;
      }
      setMandatory(event.target.checked);
      setAdditionalParams(additionalParams => {
        additionalParams.mandatory = event.target.checked;
        return additionalParams;
      });
  };

    const renderSelectedType = (param: FormItemType) => {
      switch(param) {
        case FormItemType.questionBoolean:
          return <BooleanFormItem createMode={props.createMode} setValue={setSelectedValue} language={props.formItem.language}></BooleanFormItem>;
        case FormItemType.questionText:
          return <TextFormItem createMode={props.createMode} setValue={setSelectedValue} language={props.formItem.language}></TextFormItem>;
        case FormItemType.questionRating:
          return <RatingFormItem createMode={props.createMode} setValue={setSelectedValue}></RatingFormItem>;
        case FormItemType.questionNumber:
          return <NumberFormItem createMode={props.createMode} setValue={setSelectedValue} additionalParams={additionalParams} setAdditionalParams={setAdditionalParams} language={props.formItem.language}></NumberFormItem>;
        case FormItemType.questionMultipleChoice:
          return <MultipleChoiceFormItem createMode={props.createMode} possibleValues={possibleValues ?? []} setPossibleValues={setPossibleValues} additionalParams={additionalParams} setAdditionalParams={setAdditionalParams} setValue={setSelectedValue} language={props.formItem.language}></MultipleChoiceFormItem>;
        case FormItemType.questionSingleChoice:
          return <SingleChoiceFormItem createMode={props.createMode} possibleValues={possibleValues ?? []} setPossibleValues={setPossibleValues} additionalParams={additionalParams} setAdditionalParams={setAdditionalParams} setValue={setSelectedValue} language={props.formItem.language}></SingleChoiceFormItem>;
        // Custom elements
        case FormItemType.customText:
          return <CustomTextFormItem disabled={!props.createMode} value={props.formItem.value ?? ''} setValue={setValueText}></CustomTextFormItem>
        case FormItemType.questionContainer:
          return <QuestionContainerFormItem children={formItemChildren} setChildren={setFormItemChildren} possibleValues={possibleValues ?? []} setPossibleValues={setPossibleValues} additionalParams={additionalParams} setAdditionalParams={setAdditionalParams} setValue={setSelectedValue} createMode={props.createMode} numOfDisplayedChildren={numOfDisplayedChildren} setNumOfDisplayedChildren={setNumOfDisplayedChildren} language={props.formItem.language} shouldDisplayError={displaySubmitError}></QuestionContainerFormItem>
        default:
          return <div>{t('formItem.notImplemented', {lng: createMode ? i18n.language : props.formItem.language})}</div>;
      }
    }

    const renderFormItem = () => {
      return (
        <>
          {props.createMode ?
            <>
            {props.disableDelete !== true ?
              <>
                <IconButton onClick={() => {}}></IconButton>
                  <IconButton
                    disabled={false}
                    onClick={() => {props.onDeleteFormItem(props.formItem.id)}}
                    sx={{
                      'float': 'right', 'marginBottom': '16px'
                    }}> 
                      <DeleteIcon />
                  </IconButton>
              </>
              : null}
            </> : null}

            {shouldRender && formItemType.indexOf('QUESTION') > -1 ?
              <>
                {props.createMode ? 
                  <FormControl sx={{width: 'auto', display: 'block'}}>
                    {/* Question */}
                    <TextField
                      fullWidth
                      label={props.createMode ? t('formItem.enterQuestion') : ''}
                      disabled={!props.createMode}
                      value={questionText}
                      sx={{'marginBottom': '8px'}}
                      onChange={onQuestionChanged}
                    />
                    </FormControl> : 
                    <Typography sx={{color: (displaySubmitError === true && (selectedValue === null || selectedValue === '')) ? 'red' : 'black'}}>
                      {questionText}{mandatory && formItemType !== FormItemType.questionContainer && <span style={{ fontWeight: 'bold', fontSize: '20px', paddingLeft: '8px' }}>{'(*)'}</span>}
                    </Typography>
                }
                  
                {props.createMode ? 
                  <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop:'16px'}}>
                    <FormControl sx={{flex: '1', minWidth: '250px', display: 'block', paddingBottom: '8px', paddingTop: '8px'}}>
                      <InputLabel>{t('formItem.type.type')}</InputLabel>
                      {/* Select question type */}
                      <Select
                        fullWidth
                          size='small'
                          label={t('formItem.type.type')}
                          value={formItemType}
                          sx={{'marginBottom': '8px'}}
                          onChange={handleFormItemChange}
                      >
                          <MenuItem value={FormItemType.questionBoolean}>{t('formItem.type.boolean')}</MenuItem>
                          <MenuItem value={FormItemType.questionText}>{t('formItem.type.text')}</MenuItem>
                          <MenuItem value={FormItemType.questionNumber}>{t('formItem.type.number')}</MenuItem>
                          <MenuItem value={FormItemType.questionMultipleChoice}>{t('formItem.type.multipleChoice')}</MenuItem>
                          <MenuItem value={FormItemType.questionSingleChoice}>{t('formItem.type.singleChoice')}</MenuItem>
                          <MenuItem value={FormItemType.questionRating}>{t('formItem.type.rating')}</MenuItem>
                          <MenuItem value={FormItemType.questionContainer}>{t('formItem.type.questionContainer')}</MenuItem>
                      </Select>
                    </FormControl>
                    {(formItemType !== FormItemType.customText) && (formItemType !== FormItemType.questionContainer) ? <FormGroup sx={{flex: '1', minWidth: '250px', display: 'block', paddingLeft: '16px', paddingBottom: '8px', paddingTop: '8px', justifyContent:'right'}}>
                        <FormControlLabel control={<Switch checked={mandatory} value={mandatory} onChange={handleMandatoryChange}/>} label={t('formItem.type.mandatory')} />
                    </FormGroup> : null }
                </Box>
                : null}
              </>
          : null}

            <Box sx={{paddingTop: '8px'}}>
              {(displaySubmitError === true && (selectedValue === null || selectedValue === '') && (formItemType !== FormItemType.questionContainer) && (formItemType !== FormItemType.customText)) ? 
                <Box sx={{fontSize: '13px', color: 'red', paddingBottom: '8px', display: 'flex', justifyContent: 'flex-end'}}>
                  {t('formItem.submitError', {lng: props.formItem.language})}
                </Box> : null}
                
              {/* Here will be item depending on given type */}
              {shouldRender ? renderSelectedType(formItemType) : null}
           </Box>
        </>
      )
    }
  return (
    <div ref={ref}>
      {/* // To avoid custom text displayed while empty + strange bug with breakline */}
      {(((formItemType === FormItemType.customText && !props.createMode && (!props.formItem.value || props.formItem.value === '<p><br></p>'))) ? null :
        <>
        {/* should be card displayed or not (flat display for Container) */}
        {(props.shouldDisplayCard === false) ? renderFormItem() :
          <Card sx={{width: '100%', overflow: 'visible'}}>
            <CardContent sx={{width: 'auto', display: 'block', overflow: 'visible', border: (displaySubmitError === true && (selectedValue === null || selectedValue === '')) ? "1px solid red" : ''}}>
                {renderFormItem()}
              </CardContent>
          </Card>
        }
        </>
      )}
    </div>
  );
};

export default FormItem;
