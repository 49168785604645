import { Box, Card } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
    label?: string,
    totalCount: number;
}

const TotalCountTile: React.FC<Props> = props => {
  const { t } = useTranslation(['translation']);
  return (
    <Card sx={{width: '300px', height: '300px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <h1>{props.totalCount}</h1>
            {props.label ? props.label : t('templateStatistics.timesCreated')} 
        </Box>
    </Card>
  );
};

export default TotalCountTile;
