import React, { Dispatch, SetStateAction, useState } from "react";
import { TextField } from "@mui/material";
import { NestedArrayOfString } from "../FormTemplateGenerator";
import { useTranslation } from "react-i18next";

interface TextFormItemProps {
  setValue: Dispatch<SetStateAction<null | string | Array<NestedArrayOfString | string>>>,
  createMode: boolean,
  language: string
}

const TextFormItem: React.FC<TextFormItemProps> = props => {
    const { t } = useTranslation(['translation']);
    const [text, setText] = useState('');

    const onTextChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
        props.setValue(event.target.value);
    }

  return (
    props.createMode === false ?
      <TextField
          id="text"
          label={t('formItems.text.answer', {lng: props.language})}
          multiline
          fullWidth
          size='small'
          maxRows={10}
          value={text}
          onChange={onTextChanged}
    /> : null
  );
};

export default TextFormItem;
