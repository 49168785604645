import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import english from "./localization/en.json";
import german from "./localization/de.json";

const resources = {
    en: {
        translation: english,
    },
    de: {
        translation: german,
    },
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("languageCode") ?? "en",
    });

export default i18next;