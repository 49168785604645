import React, { useState } from "react";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Stack, TextField, Typography, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useAppSelector } from "../../hooks";
import axios from "axios";
import { useTranslation } from "react-i18next";

interface UserDetailProps {
  open: boolean,
  onClose: () => void,
  create: boolean,
  user?: any,
  reload?: () => void,
  roles: any[]
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const UserDetail: React.FC<UserDetailProps> = props => {
  const { t } = useTranslation(['translation']);
  const [userId, setUserId] = useState(props.user ? props.user.id : '');

  const [login, setLogin] = useState(props.user ? props.user.login : '');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  
  const [email, setEmail] = useState(props.user ? props.user.email : '');
  const [titleBefore, setTitleBefore] = useState(props.user ? props.user.title_before : '');
  const [firstName, setFirstName] = useState(props.user ? props.user.first_name : '');
  const [lastName, setLastName] = useState(props.user ? props.user.last_name : '');
  const [titleAfter, setTitleAfter] = useState(props.user ? props.user.title_after : '');

  const [selectedRoles, setSelectedRoles] = useState<string[]>(props.user ? props.user.member_of : []);

  const [errorMessage, setErrorMessage] = useState('');

  const [loginErrorVisible, setLoginErrorVisible] = useState(false);
  const [passwordErrorVisible, setPasswordErrorVisible] = useState(false);
  const [emailErrorVisible, setEmailErrorVisible] = useState(false);

  const jwt = useAppSelector((state: any) => state.auth.jwt);

  const [edit, setEdit] = useState(false);

  const createUser = async () => {
    if (!props.create) {
        return;
    }
    
    // Admin only!
    let createUserId;
    
    if (userId.length < 1) {
        if (login.length < 1 || email.length < 1 || password.length < 1) {
            if (login.length < 1) setLoginErrorVisible(true);
            if (email.length < 1) setEmailErrorVisible(true);
            if (password.length < 1) setPasswordErrorVisible(true);
            
            // Entry not valid
            return;
        } else {
            setLoginErrorVisible(false);
            setEmailErrorVisible(false);
            setPasswordErrorVisible(false);
        }
        // First create user
        const { data } = await axios.post(`/api/UserManagement/CreateUser`, {
            'login': login,
            'email': email,
            'password': password
        },
        { headers: { 'Authorization': 'Bearer ' + jwt }});
        

        if (data.statuscode !== 0 || (data.error && data.error.length > 0)) {
            // return error and back to the first step
            setErrorMessage(data.error);
            return;
        }

        // Admin only!
        createUserId = data.user_id;
        setUserId(data.user_id);
    } else {
        createUserId = userId;
    }

    if (email && email.length < 1) {
        if (email.length < 1) setEmailErrorVisible(true);
        return;
    } else {
        setEmailErrorVisible(false);
    }
    // Additional information
    const additionalInformationData = await axios.post(`/api/UserManagement/SetUserInfo`,
    {
        'user_id': createUserId,
        'email': email,
        'title_before': titleBefore,
        'first_name': firstName,
        'last_name': lastName,
        'title_after': titleAfter
      },
      { headers: {
        'Authorization': 'Bearer ' + jwt
    }});
    
    if (additionalInformationData.data.statuscode !== 0 || (additionalInformationData.data.error && additionalInformationData.data.error.length > 0)) {
        // return error and back to the more details step
        setErrorMessage(additionalInformationData.data.error);
        return;
    }

    // Add to roles
    for (const id of selectedRoles) {
      if (props.create || props.user.member_of.indexOf(id) === -1) {
        // Add to roles
        const addToRole = await axios.post(`/api/UserManagement/AddUserToRole`, {
          'user_id': createUserId,
          'role_id': id
        },
        { headers: {
          'Authorization': 'Bearer ' + jwt
        }});
    
        if (addToRole.data.statuscode !== 0 || (addToRole.data.error && addToRole.data.error.length > 0)) {
          // return error and back to the select role step
          setErrorMessage(addToRole.data.error);
          return;
        }
      }
    }

    // Success, user was successfully created -> show message
    setErrorMessage('');
    
    if (props.reload) {
      props.reload();
    }
    props.onClose();
  }

  const onEdit = () => {
    setEdit(true);
  }

  const onCancel = () => {
    setEdit(false);
  }

  const onSave = async () => {
    // Additional information
    const additionalInformationData = await axios.post(`/api/UserManagement/SetUserInfo`,
      {
        'user_id': userId,
        'email': email,
        'title_before': titleBefore,
        'first_name': firstName,
        'last_name': lastName,
        'title_after': titleAfter
      },
      { headers: {
        'Authorization': 'Bearer ' + jwt
    }});
    
    if (additionalInformationData.data.statuscode !== 0 || (additionalInformationData.data.error && additionalInformationData.data.error.length > 0)) {
      // return error and back to the more details step
        setErrorMessage(additionalInformationData.data.error);
        return;
    }

    // Add to roles
    for (const id of selectedRoles) {
      if (props.create || props.user.member_of.indexOf(id) === -1) {
        // Add to roles
        const addToRole = await axios.post(`/api/UserManagement/AddUserToRole`, {
          'user_id': userId,
          'role_id': id
        },
        { headers: {
          'Authorization': 'Bearer ' + jwt
        }});
    
        if (addToRole.data.statuscode !== 0 || (addToRole.data.error && addToRole.data.error.length > 0)) {
          // return error and back to the select role step
          setErrorMessage(addToRole.data.error);
          return;
        }
      }
    }

    if (!props.create) {
      // Remove from roles
      for (const id of props.user.member_of) {
        if (selectedRoles.indexOf(id) === -1) {
          // Remove from role
          const removeFromRole = await axios.post(`/api/UserManagement/RemoveUserFromRole`, {
            'user_id': userId,
            'role_id': id
          },
          { headers: {
            'Authorization': 'Bearer ' + jwt
          }});
      
          if (removeFromRole.data.statuscode !== 0 || (removeFromRole.data.error && removeFromRole.data.error.length > 0)) {
            // return error and back to the select role step
            setErrorMessage(removeFromRole.data.error);
            return;
          }
        }
      }
    }
    
    if (props.reload) {
      props.reload();
    }
    setEdit(false);
  }

  const closeDialog = () => {
    if (props.reload) {
      props.reload();
    }
    props.onClose();
  }
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleRoleChange = (event: SelectChangeEvent<typeof selectedRoles>) => {
    const {
      target: { value },
    } = event;
    setSelectedRoles(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <>
      {/* Modal window for template generation*/}
      <BootstrapDialog
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {props.create ? t('userManagement.detail.createUser') : t('userManagement.detail.userDetail')}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography component={'span'} gutterBottom>
              {props.create ? t('userManagement.detail.loginMessage') : ''}
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', minWidth: '400px', paddingTop:'8px', overflowY:'hidden'}}>
              <Stack spacing={2} sx={{ width: '100%'}}>
                <TextField
                    id="outlined-login"
                    label={t('userManagement.detail.login')}
                    variant="outlined"
                    defaultValue={props.create ? '' : login}
                    disabled={!props.create || userId.length > 0}
                    error={loginErrorVisible && login.length < 1}
                    helperText={(loginErrorVisible && login.length < 1) ? t('userManagement.detail.loginRequired') : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setLogin(event.target.value)}}
                    />
                {props.create ?
                <FormControl variant="outlined" sx={{'marginTop': '8px',}}>
                    <InputLabel htmlFor="outlined-adornment-password">{t('userManagement.detail.password')}</InputLabel>
                    <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    defaultValue={props.create ? '' : password}
                    disabled={userId.length > 0}
                    error={passwordErrorVisible && password.length < 1}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setPassword(event.target.value)}}
                    endAdornment={
                        <InputAdornment position="end">
                        <IconButton
                            aria-label={t('userManagement.detail.passwordToggle')}
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                        </InputAdornment>
                    }
                    label={t('userManagement.detail.password')}
                    />
                </FormControl> : null }
                <TextField
                    id="outlined-email"
                    label={t('userManagement.detail.email')}
                    variant="outlined"
                    defaultValue={email}
                    disabled={!props.create && !edit}
                    error={emailErrorVisible && email.length < 1}
                    helperText={(emailErrorVisible && email.length < 1) ? t('userManagement.detail.emailError') : ''}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setEmail(event.target.value)}}
                    />  
                <TextField
                    id="outlined-title-before"
                    label={t('userManagement.detail.titleBefore')}
                    variant="outlined"
                    defaultValue={titleBefore}
                    disabled={!props.create && !edit}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTitleBefore(event.target.value)}}
                    />
                <TextField
                    id="outlined-first-name"
                    label={t('userManagement.detail.firstName')}
                    variant="outlined"
                    defaultValue={firstName}
                    disabled={!props.create && !edit}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setFirstName(event.target.value)}}
                    />
                <TextField
                    id="outlined-last-name"
                    label={t('userManagement.detail.lastName')}
                    variant="outlined"
                    defaultValue={lastName}
                    disabled={!props.create && !edit}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setLastName(event.target.value)}}
                    sx={{
                        'marginTop': '8px',
                    }}/>
                <TextField
                    id="outlined-title-after"
                    label={t('userManagement.detail.titleAfter')}
                    variant="outlined"
                    defaultValue={titleAfter}
                    disabled={!props.create && !edit}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setTitleAfter(event.target.value)}}
                    sx={{
                        'marginTop': '8px',
                    }}/>
                <FormControl sx={{'marginTop': '8px',}}>
                <InputLabel id="multiselect-role">{t('userManagement.detail.userRoles')}</InputLabel>
                  <Select
                    labelId="select-role"
                    id="select-role"
                    multiple
                    fullWidth
                    disabled={!props.create && !edit}
                    value={selectedRoles}
                    onChange={handleRoleChange}
                    input={<OutlinedInput label={t('userManagement.detail.userRoles')} fullWidth/>}
                    renderValue={(selected) => {
                      const renderText = [];
                      for (const id of selected) {
                        const role = props.roles.find(role => role.id === id);
                        renderText.push(role.name);
                      }
                      return renderText.join(', ');
                    } }
                    MenuProps={MenuProps}
                  >
                    {props.roles.map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        <Checkbox checked={selectedRoles.indexOf(role.id) > -1} />
                        <ListItemText primary={role.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              {errorMessage && <div className="error-message">{errorMessage}</div>}
            </Box>
          </DialogContent>
          <DialogActions>
            {props.create ? <Button autoFocus onClick={createUser}>
              {t('userManagement.detail.create')}
            </Button> : 
            (!props.create && edit) ?
              <>
                <Button autoFocus onClick={onCancel}>{t('userManagement.detail.cancel')}</Button>
                <Button autoFocus onClick={onSave}>{t('userManagement.detail.save')}</Button>
              </> :
                <Button autoFocus onClick={onEdit}>{t('userManagement.detail.edit')}</Button>
              }
          </DialogActions>
      </BootstrapDialog>
    </>
  )
};

export default UserDetail;
