import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Slider, TextField } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NestedArrayOfString } from "../FormTemplateGenerator";
import { AdditionalFormItemParams } from "./FormItem";
import { useTranslation } from "react-i18next";

interface NumberFormItemProps {
  setValue: Dispatch<SetStateAction<null | string | Array<NestedArrayOfString | string>>>,
  createMode: boolean,
  additionalParams: AdditionalFormItemParams,
  setAdditionalParams: Dispatch<SetStateAction<AdditionalFormItemParams>>,
  language: string
}

const NumberFormItem: React.FC<NumberFormItemProps> = props => {
    const { t } = useTranslation(['translation']);
    const [number, setNumber] = useState<string | undefined>(undefined);
    
    const [numberFormItemType, setNumberFormItemType] = useState<string>(props.additionalParams.numberFormItemType ?? 'numberTextInput');
    const [min, setMin] = useState<string>(props.additionalParams.minValue?.toString() ?? '0');
    const [max, setMax] = useState<string>(props.additionalParams.maxValue?.toString() ?? '10');
    const [step, setStep] = useState<string>(props.additionalParams.stepValue?.toString() ?? '1');
    const [unit, setUnit] = useState<string>(structuredClone(props.additionalParams.unit) ?? '');

    useEffect(() => {
      if (props.additionalParams.numberFormItemType === numberFormItemType || !props.createMode) return;
      props.setAdditionalParams(previous => {
        const newParams = structuredClone(previous);
        newParams.numberFormItemType = numberFormItemType;
        return newParams;
      });
    }, [numberFormItemType, props]);

    useEffect(() => {
      if (props.additionalParams.minValue === Number(min) || !props.createMode) return;
      props.setAdditionalParams(previous => {
        const newParams = structuredClone(previous);
        newParams.minValue = Number(min);
        return newParams;
      });
    }, [min, props]);

    useEffect(() => {
      if (props.additionalParams.maxValue === Number(max) || !props.createMode) return;
      props.setAdditionalParams(previous => {
        const newParams = structuredClone(previous);
        newParams.maxValue = Number(max);
        return newParams;
      });
    }, [max, props]);
    
    useEffect(() => {
      if (props.additionalParams.stepValue === Number(step) || !props.createMode) return;
      props.setAdditionalParams(previous => {
        const newParams = structuredClone(previous);
        newParams.stepValue = Number(step);
        return newParams;
      });
    }, [step, props]);
    
    useEffect(() => {
      if (props.additionalParams.unit === unit || !props.createMode) return;
      props.setAdditionalParams(previous => {
        const newParams = structuredClone(previous);
        newParams.unit = unit;
        return newParams;
      });
    }, [unit, props]);

    const onTypeChanged = (event: SelectChangeEvent) => {
      if (props.createMode === false) return;
      setNumberFormItemType(event.target.value as string);
    }
    
    const onNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
      setNumber(event.target.value);
      props.setValue(event.target.value); // TODO Switch to number too for review process
    }
    
    const onSliderChanged = (event: Event, newValue: number | number[]) => {
      setNumber(newValue.toString());
      props.setValue(newValue.toString());
    }

    const onMaxChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(event.target.value) <= Number(min)) {
        setMin((Number(event.target.value) - Number(step)).toString());
      }
      setMax(event.target.value);
    }

    const onMinChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(event.target.value) >= Number(max)) {
        setMax((Number(event.target.value) + step).toString());
      }
      setMin(event.target.value);
    }

    const onStepChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (Number(event.target.value) < 0) {
          setStep('0');
          return;
      }
      if (Number(event.target.value) > (Number(max) - Number(min))) {
        setStep((Number(max) - Number(min)).toString());
      } else {
        setStep(event.target.value);
      }
    }
    
    const onUnitChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUnit(event.target.value);
    }

    const prepareUnitLabel = () => {
      return unit ? (' ' + unit) : '';
    }

    const valueLabelFormat = (value: number) => {
      return `${value}${prepareUnitLabel()}`;
    }
    

    return (
      (props.createMode === true ? 
        <>
        <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
          <FormControl sx={{flex: '1', minWidth: '200px', display: 'block', paddingBottom: '8px', paddingTop: '8px', marginRight: '0px'}}>
              <InputLabel>{t('formItems.number.numberInputType')}</InputLabel>
                      <Select
                        fullWidth
                          size='small'
                          label={'Type'}
                          value={numberFormItemType}
                          sx={{'marginBottom': '8px'}}
                          onChange={onTypeChanged}
                      >
                          <MenuItem value={'numberTextInput'}>{t('formItems.number.numberInput')}</MenuItem>
                          <MenuItem value={'numberSlider'}>{t('formItems.number.sliderInput')}</MenuItem>
                      </Select>
                  </FormControl>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
          {numberFormItemType === 'numberSlider' ? (
            <>
            <FormControl sx={{flex: '1', minWidth: '200px', display: 'block', paddingBottom: '8px', paddingTop: '8px', marginRight: '8px'}}>
              <TextField
                label={t('formItems.number.minValue')}
                fullWidth
                size='small'
                type='number'
                value={min}
                onChange={onMinChanged}
              />
            </FormControl>
            <FormControl sx={{flex: '1', minWidth: '200px', display: 'block', paddingBottom: '8px', paddingTop: '8px', marginRight: '8px'}}>
              <TextField
                label={t('formItems.number.maxValue')}
                fullWidth
                size='small'
                type='number'
                value={max}
                onChange={onMaxChanged}
              />
            </FormControl>
            <FormControl sx={{flex: '1', minWidth: '200px', display: 'block', paddingBottom: '8px', paddingTop: '8px', marginRight: '8px'}}>
              <TextField
                label={t('formItems.number.stepValue')}
                fullWidth
                size='small'
                type='number'
                value={step}
                onChange={onStepChanged}
              />
            </FormControl>
            <FormControl sx={{flex: '1', minWidth: '200px', display: 'block', paddingBottom: '8px', paddingTop: '8px', marginRight: '8px'}}>
              <TextField
                label={t('formItems.number.unitValue')}
                fullWidth
                size='small'
                type='text'
                value={unit}
                onChange={onUnitChanged}
              />
            </FormControl>
            </>
          ) : null}
          
        </Box>
        </> : 
        <>
          {numberFormItemType === 'numberTextInput' ? (
            <TextField
              label={t('formItems.number.answer', {lng: props.language})}
              fullWidth
              size='small'
              type='number'
              value={number}
              onChange={onNumberChanged}
            />) : (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '32px' }}>
              <Box sx={{ width: '80%' }}>
                <Slider
                  defaultValue={Number(min) + (Number(max) - Number(min)) / 2}
                  onChange={onSliderChanged}
                  step={Number(step) > 0 ? Number(step) : (Number(max) - Number (min))}
                  marks={[
                    {
                      value: Number(min),
                      label: <span className="sliderLabelLeft">{min + prepareUnitLabel()}</span>,
                    },
                    {
                      value: Number(max),
                      label: <span className="sliderLabelRight">{max + prepareUnitLabel()}</span>,
                    }]}
                  min={Number(min)}
                  max={Number(max)}
                  valueLabelDisplay={number ? 'on' : 'off'}
                  valueLabelFormat={valueLabelFormat}
                />
                <div className="sliderMoveText">
                  <span>{t('formItems.number.moveSliderText', {lng: props.language})}</span>
                </div>
              </Box>
          </Box>
        )}
      </>
    )
  );
};

export default NumberFormItem;
