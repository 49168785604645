import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./Login.css"
import axios from "axios";
import { useAppDispatch } from "../../hooks";
import { setAuthenticated, setID, setJWT, setUserRole } from "../../store/slices/authSlice";
import { useNavigate } from 'react-router-dom';
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface LoginProps {
  authenticated: boolean,
  setAuthenticated: Dispatch<SetStateAction<boolean >>
}

const Login: React.FC<LoginProps> = props => {
  // Local parameters
  const [username, setUsername] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(true);
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  
  // State management
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const validateStoredCredentials = async () => {
      // setAuthenticated(true);
      const jwt = localStorage.getItem("jwt");

      if (!jwt || jwt.length < 1) {
       setLoading(false);
       return;
      }

      try {
        // Get User information - Role
        const identityData = await axios.get(`/api/Authorize/GetIdentity`, { headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + jwt
        }});
  
        if (identityData.data.valid === false) {
         dispatch(setAuthenticated(false));
         props.setAuthenticated(false);
         setLoading(false);
         return;
        }
  
        dispatch(setAuthenticated(true));
        dispatch(setJWT(jwt ?? ''));
        dispatch(setUserRole(identityData.data.account));
        dispatch(setID(identityData.data.id));
      
        // If everything is correct - continue
        props.setAuthenticated(true);
        navigate('/templates');
        setLoading(false);
        
      } catch (error) {
        setLoading(false);
      }
      
    };
    validateStoredCredentials();
  }, [dispatch, props, setLoading, navigate]);

  // Event handlers
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  // Perform Login action
  const handleLogin = async () => {
    if (username.length < 1 || password.length < 1) {
      return;
    }

    // Validate credentials with server
    const body = {
      login: username,
      password: password,
    }

    try {
      // chrome.exe --disable-web-security --allow-file-access-from-files --user-data-dir="C:\Tomas\tmp"
      // Retrieve JWT
      const tokenData = await axios.post(`/api/Authorize/GetToken`, body, { headers: {
        'Content-Type': 'application/json',
      }});

      if (tokenData.data.error && tokenData.data.error.length > 0) {
        setErrorMessage(tokenData.data.error);
      } else {
        dispatch(setAuthenticated(true));
        dispatch(setJWT(tokenData.data.jwt));
        localStorage.setItem('jwt', tokenData.data.jwt);

        // Get User information - Role
        const identityData = await axios.get(`/api/Authorize/GetIdentity`, { headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + tokenData.data.jwt
        }});

        dispatch(setUserRole(identityData.data.account));
        dispatch(setID(identityData.data.id));
        
        // If everything is correct - continue
        props.setAuthenticated(true);
        navigate('/templates');
      } 
    } catch (error) {
      
    }
    
  };

  const handleAnonymousLogin = () => {
    // Enable for private routes
    dispatch(setAuthenticated(true));
    // Force JWT to empty string (won't be used in the app)
    dispatch(setJWT(''));
    // Set user role to anonymous
    dispatch(setUserRole('none'));
    // Continue to the app
    navigate('/dashboard');
  }
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const showLoginPage = () => {
    return (
      <>
        {loading === false ? 
        <div className="login-container">
            <h1 className="login-title">Login</h1>
            <div className="login-form">
              <TextField id="outlined-applogin" label="Username" variant="outlined" defaultValue={username} onChange={handleUsernameChange} sx={{
                'marginTop': '8px',
              }}/>
              <FormControl variant="outlined" sx={{'marginTop': '8px',}}>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  defaultValue={password}
                  onChange={handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {errorMessage && <div className="error-message">{errorMessage}</div>}
              <Button variant="contained" onClick={handleLogin} size="medium" sx={{
                'marginTop': '8px',
              }}>Login</Button>
              <Button size="small" onClick={handleAnonymousLogin} sx={{
                // 'width': '100px',
                'fontStyle': 'italic',
                'marginTop': '8px',
                'alignSelf': 'flex-end',
                'fontSize': '10px'
              }}>Continue as anonymous User</Button>
            </div>
          </div>
          :
          <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ClipLoader color={"black"} />
        </div>
        }
      </>
    );
  };

  return showLoginPage();
};

export default Login;
