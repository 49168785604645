import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from "../../hooks";
import axios from "axios";
import { useTranslation } from "react-i18next";

interface UserRoleDetailProps {
  open: boolean,
  onClose: () => void,
  create: boolean,
  role?: any,
  reload?: () => void
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const UserRoleDetail: React.FC<UserRoleDetailProps> = props => {
  const { t } = useTranslation(['translation']);
  const [name, setName] = useState(props.create ? '' : props.role.name);
  const [description, setDescription] = useState(props.create ? '' : props.role.description);
  const [errorMessage, setErrorMessage] = useState('');
  const [nameErrorVisible, setNameErrorVisible] = useState(false);
  const [descriptionErrorVisible, setDescriptionErrorVisible] = useState(false);
  const jwt = useAppSelector((state: any) => state.auth.jwt);

  const submitNewRole = async () => {
    if (!props.create) {
      return;
    }

    if (name.length < 1 || description.length < 1) {
      if (name.length < 1) setNameErrorVisible(true);
      if (description.length < 1) setDescriptionErrorVisible(true);
      
      // Entry not valid
      return;
    }
    
    const { data } = await axios.post(`/api/UserManagement/CreateRole`,
      {name: name,
       description: description},
      { headers: {
        'Authorization': 'Bearer ' + jwt
    }});

    if (data.error && data.error.length > 0) {
      setErrorMessage(data.error);
    } else {
      // Reset entry
      setDescription('');
      setName('');
      
      setNameErrorVisible(false);
      setDescriptionErrorVisible(false);
      
      if (props.reload) {
        props.reload();
      }
      props.onClose();
    }
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };

  const closeDialog = () => {
    if (props.reload) {
      props.reload();
    }
    props.onClose();
  }

  return (
    <>
      {/* Modal window for template generation*/}
      <BootstrapDialog
        onClose={closeDialog}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {props.create ? t('userManagement.roleManagement.detail.createRole') : t('userManagement.roleManagement.detail.roleDetail')}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography component={'span'} gutterBottom>
              {props.create ? t('userManagement.roleManagement.detail.errorTitle') : ''}
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', minWidth: '400px'}}>
              <TextField id="outlined-name" label={t('userManagement.roleManagement.detail.name')} variant="outlined" defaultValue={name} onChange={handleNameChange} sx={{
                  'marginTop': '8px',
                }}
                error={nameErrorVisible && name.length < 1}
                disabled={!props.create}
                helperText={(nameErrorVisible && name.length < 1) ? t('userManagement.roleManagement.detail.nameError') : ''}
                />
              <TextField id="outlined-description" label={t('userManagement.roleManagement.detail.description')} variant="outlined" defaultValue={description} onChange={handleDescriptionChange} sx={{
                  'marginTop': '8px',
                }}
                multiline
                maxRows={4}
                error={descriptionErrorVisible && description.length < 1}
                disabled={!props.create}
                helperText={(descriptionErrorVisible && description.length < 1) ? t('userManagement.roleManagement.detail.descriptionError') : ''}
                />
              {errorMessage && <div className="error-message">{errorMessage}</div>}
            </Box>
          </DialogContent>
          <DialogActions>
            {props.create ? <Button autoFocus onClick={submitNewRole}>
              {t('userManagement.roleManagement.detail.create')}
            </Button> : null}
          </DialogActions>
      </BootstrapDialog>
    </>
  )
};

export default UserRoleDetail;
