export enum FormItemType {
    questionContainer = 'QUESTION_CONTAINER',
    questionText = 'QUESTION_TEXT',
    questionRating = 'QUESTION_RATING',
    questionBoolean = 'QUESTION_BOOLEAN',
    questionMultipleChoice = 'QUESTION_MULTIPLE_CHOICE',
    questionSingleChoice = 'QUESTION_SINGLE_CHOICE',
    customText = 'CUSTOM_TEXT',
    questionNumber='QUESTION_NUMBER'
}
