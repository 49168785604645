import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';

import axios from 'axios';
import './i18n'

axios.defaults.baseURL = process.env.REACT_APP_SURVEY_API;
axios.defaults.headers.post['Content-Type'] = 'application/json';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <App />
);
