import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import UserList from "./UserList";
import UserRoleList from "./UserRoleList";
import { useAppSelector } from "../../hooks";
import axios from "axios";
import { useTranslation } from "react-i18next";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      aria-labelledby={`vertical-tab-${index}`}
      style={{width: '100%', height: '100%'}}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const UserManagement: React.FC = () => {
  const { t } = useTranslation(['translation']);
  const [value, setValue] = useState(0);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);

  const jwt = useAppSelector((state: any) => state.auth.jwt);

  useEffect(() => {
    const getAllRoles = async () => {
      const { data } = await axios.post(`/api/UserManagement/GetListOfRoles`, {}, { headers: {
        'Authorization': 'Bearer ' + jwt
      }});

      if (data.roles) {
        setRoles(data.roles);
      }
    };

    const getAllUsers = async () => {
      const { data } = await axios.post(`/api/UserManagement/GetListOfUsers`, {}, { headers: {
        'Authorization': 'Bearer ' + jwt
      }});

      if (data.users) {
        setUsers(data.users);
      }
    };

    getAllRoles();
    getAllUsers();
  }, [jwt]);

  const reloadRoles = async () => {
    const { data } = await axios.post(`/api/UserManagement/GetListOfRoles`, {}, { headers: {
      'Authorization': 'Bearer ' + jwt
    }});

    if (data.roles) {
      setRoles(data.roles);
    }
  };

  const reloadUsers = async () => {
    const { data } = await axios.post(`/api/UserManagement/GetListOfUsers`, {}, { headers: {
      'Authorization': 'Bearer ' + jwt
    }});

    if (data.users) {
      setUsers(data.users);
    }
  };

  const reloadData = () => {
    reloadRoles();
    reloadUsers();
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: '100%'}}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label={t('userManagement.userManagement')} {...a11yProps(0)} />
        <Tab label={t('userManagement.roleManagement.title')} {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <UserList users={users} roles={roles} reload={reloadData}></UserList>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <UserRoleList roles={roles} reload={reloadData}></UserRoleList>
      </TabPanel>
    </Box>
  );
};

export default UserManagement;
